import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  AlertCircle,
  Bell,
  CircleX,
  Cross,
} from "lucide-react"
import { Button, GoBackButton } from "../../components/ui/button";
import Head from "../../layouts/Head";
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card";
import { FormProvider, useForm } from "react-hook-form";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { getAxiosHeaders, getAxiosUploadHeaders } from "../../misc/utils";
import axios from "axios";
import { baseURLs } from "../../misc/constants";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";
import { ProductSelect } from "../components/ProductSelect";
import { DatePicker, DateTimePicker } from "../components/DatePicker";
import moment from "moment";
import { FormSkeleton } from "../../components/ui/skeleton";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "../../components/ui/alert-dialog";

export const ExchangeRates = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);

  // Initialize the form methods
  const methods = useForm({
    defaultValues: {
      CNY: "",
      USD: ""
    },
  });

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const { handleSubmit, control, formState: { errors }, reset, setError } = methods;

  const onSubmit = (data) => {
    setRequesting(true);
    setErrorMessage('');

  
    axios.put(baseURLs.API_URL + `/settings/exchange-rate`, {
      CNY: data.CNY,
      USD: data.USD
    }, getAxiosHeaders())
      .then((response) => {
        let responseInfo = response.data;
        toggleModal("successDialog");
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const responseErrors = error.response.data.errors;
          setErrorMessage(error.response.data.error);
  
          // Check for specific field errors and set them
          if (responseErrors?.CNY) {
            setError('CNY', {
              type: 'server',
              message: responseErrors.CNY
            });
          }

          if (responseErrors?.USD) {
            setError('USD', {
              type: 'server',
              message: responseErrors.USD
            });
          }
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  };  

  const getExchangeRates = () => {
    axios.get(`${baseURLs.API_URL}/settings/exchange-rate`, {
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      let responseInfo = response.data;
      if (response.status === 204) {
        navigate(`${process.env.PUBLIC_URL}/not-found`);
        return;
      }
      let exchangeRates = responseInfo.data;

      reset({
        CNY: exchangeRates.CNY ?? "",
        USD: exchangeRates.USD ?? ""
      })

      setLoading(false);
            
    }).catch((error) => {
      try{
        let errorResponse = error.response?.data ?? '';

        if(error.response.status === 401){
          navigate(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          navigate(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          navigate(`${process.env.PUBLIC_URL}/unauthorized`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
      }
    });
  }

  useEffect(() => {
    getExchangeRates();
  }, [])

  return (
    <React.Fragment>
      <Head title="Exchange Rates" />
      <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6 lg:px-20">
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-semibold md:text-2xl">Exchange Rates</h1>
          <GoBackButton fallbackRoute={`${process.env.PUBLIC_URL}/orders`} />
        </div>
        {
          loading ?
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-5 lg:gap-6">
            <div className="grid lg:col-span-3 lg:col-start-2 gap-8">
              <FormSkeleton />
            </div>
          </div>
          :
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-5 lg:gap-6">
                <div className="grid lg:col-span-3 lg:col-start-2 gap-8">
                  {
                    errorMessage &&
                    <Alert variant="destructive">
                      <AlertCircle className="h-4 w-4" />
                      <AlertTitle>Error</AlertTitle>
                      <AlertDescription>
                        {errorMessage}
                      </AlertDescription>
                    </Alert>
                  }
                  <Card>
                    <CardContent className="pt-6 grid gap-6">
                      
                      {/* CNY Exchange Rate */}
                      <div className="grid">
                        <FormField
                          name="CNY"
                          control={control}
                          rules={{
                            required: "CNY rate is required",
                            validate: (value) =>
                              parseFloat(value) > 0 || "CNY rate must be greater than zero",
                          }}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>CNY Rate</FormLabel>
                              <FormControl>
                                <div className="relative">
                                  {/* Currency prefix */}
                                  <span className="absolute inset-y-0 left-3 flex items-center text-gray-500">
                                    GHS 1 = ¥
                                  </span>

                                  {/* Input field with prefix inside */}
                                  <Input
                                    {...field}
                                    id="CNY"
                                    type="text"
                                    className="pl-24"
                                    required
                                  />
                                </div>
                              </FormControl>
                              {errors.CNY && (
                                <FormMessage>{errors.CNY.message}</FormMessage>
                              )}
                            </FormItem>
                          )}
                        />
                      </div>

                      {/* USD Exchange Rate */}
                      <div className="grid">
                        <FormField
                          name="USD"
                          control={control}
                          rules={{
                            required: "USD rate is required",
                            validate: (value) =>
                              parseFloat(value) > 0 || "USD rate must be greater than zero",
                          }}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>USD Rate</FormLabel>
                              <FormControl>
                                <div className="relative">
                                  {/* Currency prefix */}
                                  <span className="absolute inset-y-0 left-3 flex items-center text-gray-500">
                                    $ 1 = GHS
                                  </span>

                                  {/* Input field with enough left padding */}
                                  <Input
                                    {...field}
                                    id="USD"
                                    type="text"
                                    className="pl-24"
                                    required
                                  />
                                </div>
                              </FormControl>
                              {errors.USD && (
                                <FormMessage>{errors.USD.message}</FormMessage>
                              )}
                            </FormItem>
                          )}
                        />
                      </div>
                      
                    </CardContent>
                  </Card>
                  <Card>
                    <CardContent className="pt-6">
                      <Button type="submit" className="w-full" disabled={requesting}>
                        {requesting ? "Submitting..." : "Submit"}
                      </Button>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </form>
          </FormProvider>
        }

        {/* Dialog Component */}
        {
          activeModal === "successDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Exchange Rates Updated</AlertDialogTitle>
                <AlertDialogDescription>
                  The exchange rates has been updated successfully
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }
      </main>
    </React.Fragment>
  )
}
