import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Plane,
  Plus,
  Search,
  Ship,
  ShoppingCart,
  Warehouse,
  Truck, MapPin, Package, CheckCircle,
  Trash,
  Link2,
  Container
} from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { 
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from "../../components/ui/table";
import { Button } from "../../components/ui/button";
import Head from "../../layouts/Head";
import { Badge } from "../../components/ui/badge";
import { getAxiosHeaders, getQueryParams, getShipmentStatus, ShipmentStatus } from "../../misc/utils";
import axios from "axios";
import { baseURLs } from "../../misc/constants";
import { Skeleton, TableSkeleton } from "../../components/ui/skeleton";
import { useNavigate } from "react-router-dom";
import { NoResults } from "../../components/ui/alert";
import { Checkbox } from "../../components/ui/checkbox";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "../../components/ui/dropdown-menu";
import { DotsVerticalIcon } from "@radix-ui/react-icons";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "../../components/ui/alert-dialog";
import { PaginationWithOnclick } from "../../components/PaginationWithOnClick";
import { Label } from "../../components/ui/label";
import { Input } from "../../components/ui/input";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../../components/ui/dialog";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import moment from "moment";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../components/ui/accordion";
import { ArrivedAtTransitWarehouseDialog, ArrivedInGhanaDialog, ContainerArrivedInGhanaDialog, ShippedFromSupplierDialog, ShippedToGhanaDialog, UploadShippingContainerCSVDialog } from "../components/OrderUpdateForms";

export const ShipmentContainerDetails = () => {
  const {containerNumber} = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [tableData, setTableData] = useState({
    meta: {
      total_records: 0,
    },
    data: []
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [checkAll, setCheckAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [activeModal, setActiveModal] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [hasFilters, setHasFilters] = useState(false);
  const [deleteLogID, setDeleteLogID] = useState('');
  const [selectedShipment, setSelectedShipment] = useState("");
  const [shipmentModalType, setShipmentModalType] = useState("");
  const [filters, setFilters] = useState({    
    page: 1,
    customer: '',
    order_number: '',
    tracking_number: '',
    shipment_type: 'all',
  });


  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onInputChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const onSelectChange = (value, name) => {
    setFilters({ ...filters, [name]: value });
  };

  const resetFilter = () => {
    let params = {
      page: 1,
      customer: '',
      order_number: '',
      tracking_number: '',
      shipment_type: 'all',
    };
  
    toggleModal('filterModal');
    setHasFilters(false);
    setFilters({ ...params });
    getShipments(params);
  }

  const filterShipments = () => {
    toggleModal('filterModal');
    setHasFilters(true);
    let params = filters;
    params.page = 1;
    setFilters({...params});
    getShipments(filters);
  }

  // Toggle "select all" functionality
  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      const allRowIds = tableData.data.map((shipment, index) => shipment.shipment_id);
      setSelectedRows(allRowIds);
      setCheckAll(true);
    } else {
      // If unchecked, clear all selected rows
      setSelectedRows([]);
      setCheckAll(false);
    }
  };

  const navigate = useNavigate();

  const getShipments = (filters) => {
    setIsLoading(true);
    axios.get(baseURLs.API_URL + `/orders/shipments/container-number/${containerNumber}`, {
      headers: getAxiosHeaders().headers,
      params: {
        page: filters.page,
        customer: filters.customer,
        order_number: filters.order_number,
        tracking_number: filters.tracking_number,
        status: filters.shipment_status
      }
    })
    .then((response) => {
      let responseInfo = response.data;
      if (response.status === 200) {
        setTableData({
          meta: responseInfo.data.meta,
          data: responseInfo.data.shipments,
        });

        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
      } else {
        setTableData({
          meta: {
            total_records: 0,
          },
          data: []
        })
      }

      currentUrl(filters);
      setIsLoading(false);
            
    }).catch((error) => {
      console.log({error})
      try{
        let errorResponse = error.response?.data ?? '';

        if(error.response.status === 401){
          navigate(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          navigate(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          navigate(`${process.env.PUBLIC_URL}/unauthorized`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setIsLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
      }
    });
  }

  const deleteOrderLog = () => {
    axios.delete(baseURLs.API_URL + `/orders/shipments/log/${deleteLogID}`, {
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      let responseInfo = response.data;

      toggleModal('successLogDialog');
      getShipments(filters);
      setDeleteLogID('');
      setRequesting(false);
    }).catch((error) => {
      console.log({error})
      try{
        let errorResponse = error.response?.data ?? '';

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('errorLogDialog');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
      setRequesting(false);
    });
  }

  // Delete all selected rows
  const handleDeleteSelected = () => {
    if (selectedRows.length === 0) {
        setErrorMessage(`Select at least one deal to delete`);
        toggleModal('errorDialog');
    } else {
        toggleModal('deleteDialog');
    }
  };

  const handleSingleDelete = (shipmentIDs) => {
    handleDeleteSelected([shipmentIDs]);
  };

  const confirmDelete = () => {
    checkAll ? deleteShipmentByFilter() : deleteShipmentsByIDs();    
  };

  const deleteShipmentsByIDs = () => {
    setRequesting(true);

    let _checkedBoxes = selectedRows;

    axios.delete(baseURLs.API_URL + "/orders/shipments/by-ids", {
      headers: getAxiosHeaders().headers,
      data: {
        shipments: JSON.stringify(_checkedBoxes),
      }
    })
    .then((response) => {
      let responseInfo = response.data;
      let successfulIDs = responseInfo.data.successful_ids;
      let failedIDs = responseInfo.data.failed_ids;

      if(successfulIDs.length > 0 && failedIDs.length === 0){
        getShipments(filters);        
        toggleModal('successDeleteDialog');

      } else if(successfulIDs.length > 0 && failedIDs.length > 0) {
        getShipments(filters);
        setErrorMessage(`${successfulIDs.length} ${successfulIDs.length > 1 ? `shipments` : `shipment`} were successfully deleted and ${failedIDs.length} ${failedIDs.length > 1 ? `shipments` : `shipment`} failed to get deleted`);
        toggleModal('errorDialog');

      } else {
        setErrorMessage(`Error deleting ${failedIDs.length > 1 ? `shipments` : `shipment`}. The selected ${failedIDs.length > 1 ? `shipments were` : `shipment was`} not found.`);
        toggleModal('errorDialog');
      }

      setSelectedRows([]);
      setCheckAll(false);
      setRequesting(false);
    }).catch((error) => {
      console.log({error})
      try{
        let errorResponse = error.response?.data ?? '';

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('errorDialog');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
      setRequesting(false);
    });
  }

  const deleteShipmentByFilter = () => {
    setRequesting(false);

    axios.delete(baseURLs.API_URL + "/orders/shipments/by-filters", {
      headers: getAxiosHeaders().headers,
      data: {
        order_number: filters.order_number,
        tracking_number: filters.tracking_number
      }
    })
    .then((response) => {
      getShipments(filters);
      toggleModal('successDeleteDialog');
      setCheckAll(false);
      setRequesting(false);
    }).catch((error) => {
      console.log({error})
      try{
        let errorResponse = error.response?.data ?? '';

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('errorDialog');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
      setRequesting(false);
    });
  }

  const currentUrl = (filters) => {
    let order_number = filters.order_number.length > 0 ? `&on=${filters.order_number}` : ``;
    let tracking_number = filters.tracking_number.length > 0 ? `&tn=${filters.tracking_number}` : ``;
    let shipment_type = filters.shipment_type !== null ? `&t=${filters.shipment_type}` : `all`;
    
    if( (order_number !== '' || tracking_number !== '' ||
      filters.shipment_type !== 'all'
    ) 
      && !hasFilters) {
      setHasFilters(true)
    }

    let params = `${order_number}${tracking_number}${shipment_type}`;
    let url = `${process.env.PUBLIC_URL}${window.location.pathname}?p=${filters.page}${params}`;
    navigate(url, { replace: true })
  }

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getShipments(params)
  }

  useEffect(() => {
    if(selectedShipment){
      toggleModal(`${shipmentModalType}`);
    }
  },[selectedShipment])

  useEffect(() => {
    let url_string = window.location.href;
    let url = new URL(url_string);
    let queryParams = getQueryParams(url);

    let page = queryParams.hasOwnProperty('p') ? queryParams.p : 1;
    let order_number = queryParams.hasOwnProperty('on') ? queryParams.on : '';
    let tracking_number = queryParams.hasOwnProperty('tn') ? queryParams.tn : '';       
    let shipment_type = queryParams.hasOwnProperty('t') ? queryParams.t : 'all';    

    let params = filters;
    params.page = page;
    params.order_number = order_number;
    params.tracking_number = tracking_number;
    params.shipment_type = shipment_type;

    getShipments(params);
  }, [])

  return (
    <React.Fragment>
      <Head title="Container Shipments" />
      <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6 lg:px-20">
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-semibold md:text-2xl">Container Shipments - {containerNumber}</h1>
        </div>

        {
          isLoading ?
          <div>
            <Skeleton className="h-48 rounded-md mb-4" />
            <Skeleton className="h-48 rounded-md mb-4" />
            <Skeleton className="h-48 rounded-md mb-4" />
          </div>
          :
          <Card x-chunk="dashboard-05-chunk-3">
            <CardHeader className="px-7 flex-row justify-between">
              <CardTitle className="text-lg content-center">{tableData.meta.total_records} {tableData.meta.total_records > 1 ? `Shipments` : `Shipment`}</CardTitle>
              <Button variant="outline" onClick={() => toggleModal("filterModal")}><Search className="w-4 h-4 mr-2"  />Filter</Button>
            </CardHeader>
            <CardContent>
              {
                tableData.data.length === 0 ?
                <NoResults message="No shipment found" />
                :
                <>
                  {tableData.data.map((shipment) => (
                    <Card key={shipment.shipment_id} className="mb-5">
                      <CardContent className="p-4">
                        <div className="flex items-center space-x-4">
                          <img
                            src={shipment.products[0].image}
                            alt={shipment.products[0].product_name}
                            width={80}
                            height={80}
                            className="rounded-md object-cover flex-shrink-0"
                          />
                          <div className="flex-grow grid grid-cols-3 gap-x-4 gap-y-2">
                            <div className="col-span-3">
                              <p className="font-semibold text-lg cursor-pointer" onClick={() => navigate(`${process.env.PUBLIC_URL}/shipments/details/${shipment.shipment_id}`)}>{shipment.products[0].product_name}</p>
                              <p className="text-sm text-muted-foreground"><ShipmentStatus status={shipment.status} /></p>
                            </div>
                            <p className="text-sm">
                              <span className="font-semibold">Order:</span> {shipment.order_number}
                            </p>
                            <p className="text-sm flex">
                              <span className="font-semibold mr-2">Type:</span>
                              {
                                shipment.shipment_type === 'sea' ?
                                <span className="flex items-center">
                                  <Ship className="mr-2 h-4 w-4" />
                                  Sea
                                </span>
                                :
                                <span className="flex items-center">
                                  <Plane className="mr-2 h-4 w-4" />
                                  Air
                                </span>
                              }
                            </p>
                            <p className="text-sm">
                              <span className="font-semibold">Quantity:</span> {shipment.quantity}
                            </p>
                            <p className="text-sm">
                              <span className="font-semibold">Created By:</span> {shipment.created_by}
                            </p>
                            <p className="text-sm">
                              <span className="font-semibold">Created On:</span> {shipment.created_at}
                            </p>
                            <p className="text-sm">
                              <span className="font-semibold">Updated On:</span> {shipment.updated_at}
                            </p>
                          </div>
                          <Button onClick={() => navigate(`${process.env.PUBLIC_URL}/shipments/details/${shipment.shipment_id}`)}>View Shipment</Button>
                        </div>
                      </CardContent>
                    </Card>
                  ))}
                  <div className="mt-5 justify-center">
                    <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />
                  </div>
                </>
              }
            </CardContent>
          </Card>
        }
        {
          activeModal === "deleteDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>
                  {selectedRows.length === 1 ? "Delete Shipment" : "Delete Selected Shipments"}
                </AlertDialogTitle>
                <AlertDialogDescription>
                  {selectedRows.length === 1
                    ? "Are you sure you want to delete this shipment? This action cannot be undone."
                    : "Are you sure you want to delete all selected shipments? This action cannot be undone."}
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel onClick={() => toggleModal(null)}>Cancel</AlertDialogCancel>
                {
                  requesting ?
                  <AlertDialogAction disabled>Deleting...</AlertDialogAction>
                  :
                  <AlertDialogAction className="bg-red-600 text-white hover:bg-red-700" onClick={confirmDelete}>Confirm</AlertDialogAction>
                }
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === "successDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Shipment Updated</AlertDialogTitle>
                <AlertDialogDescription>
                  The shipment status has been updated successfully
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === "successDeleteDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Deleted Successfully</AlertDialogTitle>
                <AlertDialogDescription>
                  Shipment(s) deleted successfully
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === "errorDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal("errorDialog")}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Error</AlertDialogTitle>
                <AlertDialogDescription>
                  {errorMessage}
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === 'filterModal' &&
          <Dialog open={true} onOpenChange={() => toggleModal(null)}>
            <DialogContent>
              <DialogHeader className="mb-4">
                <DialogTitle>Filter Shipments</DialogTitle>
              </DialogHeader>
                <div className="space-y-4">
                  <div className="grid gap-2">
                    <Label htmlFor="customer">Customer</Label>
                    <Input
                      id="customer"
                      name="customer"
                      type="text"
                      placeholder="Enter a customer name or phone number"
                      onChange={onInputChange}
                      defaultValue={filters.customer}
                    />
                  </div>
                  <div className="grid gap-2">
                    <Label htmlFor="order_number">Order Number</Label>
                    <Input
                      id="order_number"
                      name="order_number"
                      type="text"
                      placeholder="Enter an order number"
                      onChange={onInputChange}
                      defaultValue={filters.order_number}
                    />
                  </div>

                  <div className="grid gap-2">
                    <Label htmlFor="tracking_number">Tracking Number</Label>
                    <Input
                      id="tracking_number"
                      name="tracking_number"
                      type="text"
                      placeholder="Enter a tracking number"
                      onChange={onInputChange}
                      defaultValue={filters.tracking_number}
                    />
                  </div>

                  {/* <div className="grid gap-2">
                    <Label htmlFor="shipment_status">Shipment Status</Label>
                    <select 
                      name="shipment_status"
                      disabled
                      className="rounded-md border border-gray-300 p-2"
                      onChange={(e) => onSelectChange(e.target.value, "shipment_status")} 
                      value={filters.shipment_status}>
                      <option key="all" value="all">All</option>
                      <option key="ordered" value="ordered">Ordered</option>
                      <option key="shipped_from_supplier" value="shipped_from_supplier">Shipped from Supplier</option>
                      <option key="at_transit_warehouse" value="at_transit_warehouse">Arrived at Transit Warehouse</option>
                      <option key="shipped_to_ghana" value="shipped_to_ghana">Shipped to Ghana</option>
                      <option key="arrived_in_ghana" value="arrived_in_ghana">Arrived in Ghana</option>
                    </select>
                  </div> */}

                  <div className="grid gap-2">
                    <Label htmlFor="shipment_type">Shipment Type</Label>
                    <select 
                      name="shipment_type"
                      className="rounded-md border border-gray-300 p-2"
                      onChange={(e) => onSelectChange(e.target.value, "shipment_type")} 
                      value={filters.shipment_type}>
                      <option key="all" value="all">All</option>
                      <option key="air" value="air">Air</option>
                      <option key="sea" value="sea">Sea</option>
                    </select>
                  </div>
                </div>

                {/* Dialog Footer */}
                <DialogFooter>
                  <Button onClick={(ev) => { ev.preventDefault(); filterShipments();} }>Apply Filter</Button>
                  {
                    hasFilters &&
                    <Button className="ms-3" variant="secondary" onClick={(ev) => { ev.preventDefault(); resetFilter();} }>
                      Reset Filter
                    </Button>
                  }
                </DialogFooter>
            </DialogContent>
          </Dialog>
        }

        {/* update shipment status modals */}
        {
          activeModal === 'shippedFromSupplierModal' && 
          <ShippedFromSupplierDialog toggleModal={(state) => {toggleModal(state === 'success' ? 'successDialog' : null); setSelectedShipment("")}} shipmentID={selectedShipment.shipment_id} getShipmentDetails={() => getShipments(filters)} />
        }        

        {
          activeModal === 'atTransitWarehouseModal' && 
          <ArrivedAtTransitWarehouseDialog toggleModal={(state) => {toggleModal(state === 'success' ? 'successDialog' : null); setSelectedShipment("")}} shipmentID={selectedShipment.shipment_id} getShipmentDetails={() => getShipments(filters)} shipmentType={selectedShipment.shipment_type} />
        }

        {
          activeModal === 'shippedToGhanaModal' && 
          <ShippedToGhanaDialog toggleModal={(state) => {toggleModal(state === 'success' ? 'successDialog' : null); setSelectedShipment("")}} shipmentID={selectedShipment.shipment_id} getShipmentDetails={() => getShipments(filters)} />
        }

        {
          activeModal === 'AddContainerCSVModal' &&
          <UploadShippingContainerCSVDialog toggleModal={(state) => {toggleModal(state === 'success' ? 'successDialog' : null); setSelectedShipment("")}} getShipmentDetails={() => getShipments(filters)} />
        }

        {
          activeModal === 'arrivedInGhanaModal' && 
          <ContainerArrivedInGhanaDialog toggleModal={(state) => {toggleModal(state === 'success' ? 'successDialog' : null); setSelectedShipment("")}} containerNumber={selectedShipment.shipment_tracking_number} getShipmentDetails={() => getShipments(filters)} />
        }

        {
          activeModal === "deleteLogDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>
                  Delete Shipment Log?
                </AlertDialogTitle>
                <AlertDialogDescription>
                  Are you sure you want to delete this shipment log? This action cannot be undone.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel onClick={() => toggleModal(null)}>Cancel</AlertDialogCancel>
                {
                  requesting ?
                  <AlertDialogAction disabled>Deleting...</AlertDialogAction>
                  :
                  <AlertDialogAction className="bg-red-600 text-white hover:bg-red-700" onClick={deleteOrderLog}>Delete</AlertDialogAction>
                }
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === "successLogDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Deleted Successfully</AlertDialogTitle>
                <AlertDialogDescription>
                  Shipment log deleted successfully
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }
      </main>
    </React.Fragment>
  )
}
