import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  AlertCircle,
  Bell,
  CircleX,
  Cross,
  Edit,
  FileUp,
  Pencil,
  Plus,
  Trash,
  X,
} from "lucide-react"
import { Button, GoBackButton } from "../../components/ui/button";
import Head from "../../layouts/Head";
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Textarea } from "../../components/ui/textarea";
import { generateVariants, getAxiosHeaders, getAxiosUploadHeaders, validateUrl } from "../../misc/utils";
import axios from "axios";
import { baseURLs } from "../../misc/constants";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";
import { Badge } from "../../components/ui/badge";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../components/ui/accordion";
import { AddVariantTypeDialog, EditVariantTypeDialog } from "../../components/VariantManager";
import { Label } from "../../components/ui/label";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "../../components/ui/alert-dialog";
import CategorySelect from "../components/CategorySelect";
import { UnitSelect } from "../components/UnitSelect";
import { Separator } from "../../components/ui/separator";

export const AddProduct = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [requesting, setRequesting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [images, setImages] = useState([""]);
  const [videos, setVideos] = useState([""]);
  const [activeModal, setActiveModal] = useState(null);
  const [productID, setProductID] = useState(null);
  
  const orderID = searchParams.get("order") || null;

  // Initialize the form methods
  const methods = useForm({
    defaultValues: {
      product_name: "",
      description: "",
      tags: [],
      cost: 0,
      moq: 0,
      unit: "pieces",
      category: "",
      supplier_name: "",
      platform_name: "",
      product_url: "",
      variants: [],
      variant_types: [],
      images: [],
      videos: []
    },
  });
  const [tags, setTags] = useState([]);
  const [currentTag, setCurrentTag] = useState("");
  const [variantTypes, setVariantTypes] = useState([]);
  const [variants, setVariants] = useState([]);
  const [editVariantTypeIndex, setEditVariantTypeIndex] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const { handleSubmit, control, formState: { errors }, watch, getValues, setValue, reset, setError } = methods;

  // Function to handle adding a new image input field
  const addImageField = () => {
    setImages([...images, ""]); // Add a new empty input to the images array
  };

  // Function to handle removing an image input field
  const removeImageField = (index) => {
    setImages(images.filter((_, i) => i !== index)); // Remove the selected image input
  };

  // Function to handle adding a new video input field
  const addVideoField = () => {
    setVideos([...videos, ""]); // Add a new empty input to the images array
  };

  // Function to handle removing an video input field
  const removeVideoField = (index) => {
    setVideos(videos.filter((_, i) => i !== index)); // Remove the selected image input
  };

  // Handle adding options as pills
  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === ",") {
      event.preventDefault();
      if (currentTag.trim() !== "") {
        setTags([...tags, currentTag.trim()]);
        setCurrentTag("");
      }
    }
  };

  const resetForm = () => {
    reset();
    setVariantTypes([]);
    setVariants([]);
    setImages([]);
    setVideos([]);
  }

  // Handle removing a pill
  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
  };

  // Function to handle change in image input fields
  const handleImageChange = (index, value) => {
    const updatedImages = [...images];
    updatedImages[index] = value;
    setImages(updatedImages);
  };

  // Function to handle change in video input fields
  const handleVideoChange = (index, value) => {
    const updatedVideos = [...videos];
    updatedVideos[index] = value;
    setVideos(updatedVideos);
  };

  // Function to handle change in variant input fields
  const handleVariantUnitCostChange = (index, value) => {
    const updatedVariants = [...variants];
    updatedVariants[index].unit_cost = value;
    setVariants(updatedVariants);
  };

  // Function to handle change in variant input fields
  const handleVariantImageChange = (index, value) => {
    const updatedVariants = [...variants];
    updatedVariants[index].image = value;
    setVariants(updatedVariants);
  };

  const onSubmit = (data) => {
    if(tags.length === 0){
      return;
    }

    setRequesting(true);
    setErrorMessage('');

    axios.post(baseURLs.API_URL + "/products/add", {
      product_name: data.product_name,
      description: data.description,
      cost: data.cost,
      moq: data.moq,
      unit: data.unit,
      category: data.category,
      tags: JSON.stringify(tags),
      supplier_name: data.supplier_name,
      platform_name: data.platform_name,
      product_url: data.product_url,
      variants: JSON.stringify(variants ?? []),
      variant_types: JSON.stringify(variantTypes ?? []),
      images: JSON.stringify(images ?? []),
      videos: JSON.stringify(videos ?? []),
      order_id: orderID
    }, getAxiosHeaders())
      .then((response) => {
        let responseInfo = response.data;
        let _productID = responseInfo.data.product_id;
        setProductID(_productID)
        toggleModal("successDialog");
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const responseErrors = error.response.data.errors;
          setErrorMessage(error.response.data.error);
  
          // Check for specific field errors and set them
          if (responseErrors.product_name) {
            setError('product_name', {
              type: 'server',
              message: responseErrors.product_name
            });
          }
  
          if (responseErrors.description) {
            setError('description', {
              type: 'server',
              message: responseErrors.description
            });
          }
  
          if (responseErrors.cost) {
            setError('cost', {
              type: 'server',
              message: responseErrors.cost
            });
          }

          if (responseErrors.moq) {
            setError('moq', {
              type: 'server',
              message: responseErrors.moq
            });
          }

          if (responseErrors.tags) {
            setError('tags', {
              type: 'server',
              message: responseErrors.tags
            });
          }

          if (responseErrors.unit) {
            setError('unit', {
              type: 'server',
              message: responseErrors.unit
            });
          }

          if (responseErrors.category) {
            setError('category', {
              type: 'server',
              message: responseErrors.category
            });
          }

          if (responseErrors.supplier_name) {
            setError('supplier_name', {
              type: 'server',
              message: responseErrors.supplier_name
            });
          }

          if (responseErrors.platform_name) {
            setError('platform_name', {
              type: 'server',
              message: responseErrors.platform_name
            });
          }

          if (responseErrors.product_url) {
            setError('product_url', {
              type: 'server',
              message: responseErrors.product_url
            });
          }

          if (responseErrors.images) {
            setError('images', {
              type: 'server',
              message: responseErrors.images
            });
          }

          if (responseErrors.videos) {
            setError('videos', {
              type: 'server',
              message: responseErrors.videos
            });
          }

          if (responseErrors.variants) {
            setError('variants', {
              type: 'server',
              message: responseErrors.variants
            });
          }

          if (responseErrors.variant_types) {
            setError('variant_types', {
              type: 'server',
              message: responseErrors.variant_types
            });
          }
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  }; 

  const handleProductNameChange = (productName, unitCost) => {
    if (variantTypes.length > 0) {
      // You can now use both productName and unitCost
      const productVariants = generateVariants(variantTypes, variants, productName, unitCost);
      console.log({ productVariants });
  
      // Update the form with the new variants using `setValue`
      setVariants([...productVariants]);
      setValue("variants", productVariants);
    }
  };

  const handlePriceChange = (unitCost) =>  {
    console.log({variants, unitCost});
    if(variants.length > 0){
      let _variants = variants;
      let updatedVariants = _variants.map(variant => {
        if (variant.unit_cost == null || variant.unit_cost === '' || variant.unit_cost === 0 ) {
          console.log({unitCost,variant})
          return {
              ...variant,
              unit_cost: unitCost
          };
        }
        return variant;
      });

      setVariants([...updatedVariants]);
      setValue("variants", updatedVariants);
      console.log({form_variants: getValues("variants")});
    }

  };

  const handleEditVariantType = (variantIndex) => {
    setEditVariantTypeIndex(variantIndex);
    toggleModal("editVariantTypeDialog");
  };

  const handleDeleteVariantType = (variantIndex) => {
    const _variantTypes = [...variantTypes];
    _variantTypes.splice(variantIndex, 1);

    let productName = getValues("product_name");
    let unitCost = getValues("cost");

    if(_variantTypes.length > 0){
      const productVariants = generateVariants(variantTypes, variants, productName, unitCost);
  
      // Update the form with the new variants using `setValue`
      setVariants([...productVariants]);
      setValue("variants", productVariants);
    
    } else {
      setVariants([]);
      setValue("variants", []);
    }

  };
  
  useEffect(() => {
    // Watch multiple fields
    const [productName, unitCost] = watch(['product_name', 'cost']);
    
    if (productName) {
      handleProductNameChange(productName, unitCost);
    }

    if (unitCost) {
      handlePriceChange(unitCost);
    }
  }, [watch('product_name'), watch('cost')]);

  return (
    <React.Fragment>
      <Head title="Add Product" />
      <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6 lg:px-20">
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-semibold md:text-2xl">Add a New Product</h1>
          <div className="flex gap-x-2">
            <Link to={`${process.env.PUBLIC_URL}/products/import`}>
                <Button><FileUp className="mr-2" /> Import Products</Button>
            </Link>
            <GoBackButton fallbackRoute={`${process.env.PUBLIC_URL}/products`} />
          </div>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-5 lg:gap-6">
              <div className="grid lg:col-span-3 lg:col-start-2 gap-8">
                {
                  errorMessage &&
                  <Alert variant="destructive">
                    <AlertCircle className="h-4 w-4" />
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription>
                      {errorMessage}
                    </AlertDescription>
                  </Alert>
                }
                <Card>
                  <CardHeader className="pb-3"> 
                    <CardTitle className="text-lg">Product Information</CardTitle>
                  </CardHeader>
                  <CardContent className="grid gap-4">
                    {/* Product name Field */}
                    <FormField
                      name="product_name"
                      control={control}
                      rules={{
                        required: "Product name is required",
                      }}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Product Name</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              id="product_name"
                              type="text"
                              placeholder="eg. Cook pot set"
                              required
                            />
                          </FormControl>
                          {errors.product_name && (
                            <FormMessage>{errors.product_name.message}</FormMessage>
                          )}
                        </FormItem>
                      )}
                    />

                    {/* Description Field */}
                    <FormField
                      name="description"
                      control={control}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Description</FormLabel>
                          <FormControl>
                            <Textarea
                              {...field}
                              id="description"
                              placeholder="eg. I will like..."
                            />
                          </FormControl>
                          {errors.description && (
                            <FormMessage>{errors.description.message}</FormMessage>
                          )}
                        </FormItem>
                      )}
                    />

                    {/* Images Field */}
                    <FormItem>
                      <FormLabel>Image URLs</FormLabel>
                      {images.map((image, index) => (
                        <div key={index}>
                          <div className="flex items-center mb-2">
                            <Controller
                              name={`images[${index}]`} // Provide unique name for each image field
                              control={control}
                              rules={{ validate: validateUrl }} // Add validation rule
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  onChange={(e) => {
                                    field.onChange(e); // Update form state with react-hook-form's onChange
                                    handleImageChange(index, e.target.value); // Optional: update local state if needed
                                  }}
                                  placeholder="Enter image URL"
                                />
                              )}
                            />
                            {images.length > 1 && (
                              <Button
                                type="button"
                                variant="destructive"
                                onClick={() => removeImageField(index)}
                                className="ml-2"
                              >
                                Remove
                              </Button>
                            )}
                          </div>
                          {errors.images?.[index] && (
                            <FormMessage>{errors.images[index]?.message}</FormMessage>
                          )}
                        </div>
                      ))}
                      <Button variant="secondary" type="button" onClick={addImageField}>
                        Add another image URL
                      </Button>
                    </FormItem>

                    <hr/>

                    {/* Video Field */}
                    <FormItem>
                      <FormLabel>Video URLs</FormLabel>
                      {videos.map((video, index) => (
                        <div key={'video'+index}>
                          <div className="flex items-center mb-2">
                            <Controller
                              name={`videos[${index}]`} // Provide unique name for each image field
                              control={control}
                              rules={{ validate: validateUrl }} // Add validation rule
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  onChange={(e) => {
                                    field.onChange(e); // Update form state with react-hook-form's onChange
                                    handleVideoChange(index, e.target.value); // Optional: update local state if needed
                                  }}
                                  placeholder="Enter video URL"
                                />
                              )}
                            />
                            {videos.length > 1 && (
                              <Button
                                type="button"
                                variant="destructive"
                                onClick={() => removeVideoField(index)}
                                className="ml-2"
                              >
                                Remove
                              </Button>
                            )}
                          </div>
                          {errors.images?.[index] && (
                            <FormMessage>{errors.images[index]?.message}</FormMessage>
                          )}
                        </div>
                      ))}
                      <Button variant="secondary" type="button" onClick={addVideoField}>
                        Add another video URL
                      </Button>
                    </FormItem>

                    <hr/>

                    <div className="flex space-x-4">
                      {/* Unit Cost Field */}
                      <FormField
                        name="cost"
                        control={control}
                        rules={{
                          required: "Unit cost is required",
                          validate: (value) =>
                            parseFloat(value) > 0 || "Unit cost must be greater than zero",
                        }}
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Unit Cost</FormLabel>
                            <FormControl>
                              <div className="relative">
                                {/* Input field with prefix inside */}
                                <Input
                                  {...field}
                                  id="cost"
                                  type="text"
                                  className="pl-6"
                                  required
                                />
                                {/* Currency prefix inside the input */}
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-gray-500">¥</span>
                              </div>
                            </FormControl>
                            {errors.cost && (
                              <FormMessage>{errors.cost.message}</FormMessage>
                            )}
                          </FormItem>
                        )}
                      />

                      {/* MOQ Field */}
                      <FormField
                        name="moq"
                        control={control}
                        rules={{
                          required: "MOQ is required",
                          validate: (value) =>
                            parseInt(value, 10) > 0 || "MOQ must be greater than zero",
                        }}
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>MOQ</FormLabel>
                            <FormControl>
                              <Input
                                {...field}
                                id="moq"
                                type="number"
                                placeholder="eg. 100"
                                required
                              />
                            </FormControl>
                            {errors.moq && (
                              <FormMessage>{errors.moq.message}</FormMessage>
                            )}
                          </FormItem>
                        )}
                      />

                      {/* Unit Field */}
                      <FormField name="unit"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Unit</FormLabel>
                            <FormControl>
                              <UnitSelect 
                                field={field}
                                onChange={(value) => setValue("unit", value)} 
                              />
                            </FormControl>
                            {errors.unit && <FormMessage>{errors.unit.message}</FormMessage>}
                          </FormItem>
                        )}
                      />
                    </div>

                    <hr/>

                    {/* Category Field */}
                    <FormField name="category" 
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Category</FormLabel>
                          <FormControl>
                            <CategorySelect
                              field={field}
                              onChange={(value) => setValue("category", value)} 
                            />
                          </FormControl>
                          {errors.category && <FormMessage>{errors.category.message}</FormMessage>}
                        </FormItem>
                      )}
                    />

                    <hr />

                    <div className="grid gap-3">
                      <Label htmlFor="variant_options">Tags</Label>
                      <Input
                        id="tagInput"
                        type="text"
                        placeholder="Enter option and press Enter or ,"
                        value={currentTag}
                        onChange={(e) => setCurrentTag(e.target.value)}
                        onKeyDown={handleKeyDown}
                      />
                      {tags.length === 0 && (
                        <p className="text-red-500 text-sm mt-1">
                          Please add at least one tag.
                        </p>
                      )}
                      <div className="flex flex-wrap gap-2 mt-2">
                        {tags.map((tag, index) => (
                          <div
                            key={index}
                            className="flex items-center space-x-2 bg-gray-200 px-3 py-1 rounded-full text-sm"
                          >
                            <span>{tag}</span>
                            <button
                              type="button"
                              onClick={() => handleRemoveTag(tag)}
                              className="text-gray-600 hover:text-gray-800"
                            >
                              <X size={14} />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                    

                  </CardContent>
                </Card>
                <Card>
                  <CardHeader className="pb-3">
                    <div className="flex justify-between mb-2">
                      <CardTitle className="text-lg content-center">Variant Information</CardTitle>
                      <Button type="button" size="sm" variant="outline" onClick={() => {toggleModal('addVariantTypeDialog')}}><Plus className="w-4 h-4 mr-2" /> Add Variant Type</Button>
                    </div> 
                  </CardHeader>
                  {
                    variants.length > 0 &&
                    <CardContent>
                      
                      <div className="flex flex-col gap-2 rounded-md border p-4">
                        {
                          variantTypes.map((variantType, index) => {
                            return (
                              <React.Fragment key={index}>
                                <div className="flex-1 pb-2">
                                  <div className="flex justify-between">
                                    <p className="text-sm font-medium content-center leading-none">
                                      {variantType.type}
                                    </p>
                                    <div>
                                      <Button type="button" size="sm" variant="ghost" onClick={() => handleEditVariantType(index)}><Pencil className="w-4 h-4" /></Button>
                                      <Button className="text-red-500 hover:text-red-600" type="button" size="sm" variant="ghost" onClick={() => handleDeleteVariantType(index)}><Trash className="w-4 h-4" /></Button>
                                    </div>
                                  </div>
                                  <div className="text-sm flex gap-1">
                                    {
                                      variantType.options.map((option, jIndex) => {
                                        return <Badge variant="outline" key={jIndex}>{option}</Badge>
                                      })
                                    }
                                  </div>

                                </div>
                                {index > 0 && <hr  />}
                              </React.Fragment>
                            )
                          })
                        }
                      </div>
                        
                      <div className="p-2">
                        <Accordion type="single" collapsible className="w-full">
                          <AccordionItem value="item-1">
                            <AccordionTrigger>{variants.length} {variants.length > 1 ? `Variants` : `Variant`}</AccordionTrigger>
                            <AccordionContent>
                              {
                                variants.map((variant, index) => {
                                  return( 
                                  <>
                                    <div className="grid grid-cols-12 gap-4 p-3" key={index}>
                                      {/* Variant name Field */}
                                      <div className="col-span-12 lg:col-span-8">
                                        <FormField
                                          name={`variants[${index}].product_name`}
                                          control={control}
                                          render={({ field }) => (
                                            <FormItem>
                                              <FormLabel>Variant Name</FormLabel>
                                              <FormControl>
                                                <Input
                                                  {...field}
                                                  type="text"
                                                  value={variant.product_name}
                                                  disabled
                                                  required
                                                />
                                              </FormControl>
                                              {errors.variants?.[index]?.variant_name && (
                                                <FormMessage>{errors.variants?.[index]?.variant_name.message}</FormMessage>
                                              )}
                                            </FormItem>
                                          )}
                                        />
                                      </div>
        
                                      {/* cost Field */}
                                      <div className="col-span-12 lg:col-span-4">
                                        <FormField
                                          name={`variants[${index}].unit_cost`}
                                          control={control}
                                          rules={{
                                            required: "Unit cost is required",
                                            validate: (value) =>
                                              parseFloat(value) > 0 || "Unit cost must be greater than zero",
                                          }}
                                          render={({ field }) => (
                                            <FormItem>
                                              <FormLabel>Unit Cost</FormLabel>
                                              <FormControl>
                                                <div className="relative">
                                                  {/* Input field with prefix inside */}
                                                  <Input
                                                    {...field}
                                                    id="unit_cost"
                                                    type="number"
                                                    className="pl-10"
                                                    value={variant.unit_cost}
                                                    onChange={(e) => handleVariantUnitCostChange(index, e.target.value)}
                                                    required
                                                  />
                                                  {/* Currency prefix inside the input */}
                                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-gray-500">
                                                    ¥
                                                  </span>
                                                </div>
                                              </FormControl>
                                              {errors.variants?.[index]?.unit_cost && (
                                                <FormMessage>{errors.variants?.[index]?.unit_cost.message}</FormMessage>
                                              )}
                                            </FormItem>
                                          )}
                                        />
                                      </div>

                                      {/* Variant image Field */}
                                      <div className="col-span-12 lg:col-span-12">
                                        <FormField
                                          name={`variants[${index}].image`}
                                          control={control}
                                          rules={{ required: false, validate: validateUrl }}
                                          render={({ field }) => (
                                            <FormItem>
                                              <FormLabel>Variant Image</FormLabel>
                                              <FormControl>
                                                <Input
                                                  {...field}
                                                  type="text"
                                                  value={variant.image}
                                                  onChange={(e) => handleVariantImageChange(index, e.target.value)}
                                                />
                                              </FormControl>
                                              {errors.variants?.[index]?.image && (
                                                <FormMessage>{errors.variants?.[index]?.image.message}</FormMessage>
                                              )}
                                            </FormItem>
                                          )}
                                        />
                                      </div>
                                    </div>
                                    {
                                      variants.length - 1 !== index &&
                                      <Separator className="my-4" />
                                    }
                                  </>)
                                })
                              }
                              
                            </AccordionContent>
                          </AccordionItem>
                        </Accordion>
                      </div>
                    </CardContent>
                  }
                </Card>
                <Card>
                  <CardHeader className="pb-3"> 
                    <CardTitle className="text-lg">Supplier Information</CardTitle>
                  </CardHeader>
                  <CardContent className="grid gap-4">
                    {/* supplier name Field */}
                    <FormField
                      name="supplier_name"
                      control={control}
                      rules={{
                        required: "Supplier name is required",
                      }}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Supplier Name</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              id="supplier_name"
                              type="text"
                              placeholder="eg. Hui DuoDu"
                              required
                            />
                          </FormControl>
                          {errors.supplier_name && (
                            <FormMessage>{errors.supplier_name.message}</FormMessage>
                          )}
                        </FormItem>
                      )}
                    />

                    {/* Platform name Field */}
                    <FormField
                      name="platform_name"
                      control={control}
                      rules={{
                        required: "Platform name is required",
                      }}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Platform Name</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              id="platform_name"
                              type="text"
                              placeholder="eg. Hui DuoDu App"
                              required
                            />
                          </FormControl>
                          {errors.platform_name && (
                            <FormMessage>{errors.platform_name.message}</FormMessage>
                          )}
                        </FormItem>
                      )}
                    />

                    {/* Product url Field */}
                    <FormField
                      name="product_url"
                      control={control}
                      rules={{
                        required: "Product url is required",
                        validate: validateUrl
                      }}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Product URL</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              id="product_url"
                              type="text"
                              placeholder="eg. https://product.com/bag"
                              required
                            />
                          </FormControl>
                          {errors.product_url && (
                            <FormMessage>{errors.product_url.message}</FormMessage>
                          )}
                        </FormItem>
                      )}
                    />

                  </CardContent>
                </Card>
                <Card>
                  <CardContent className="pt-6">
                    <Button type="submit" className="w-full" disabled={requesting}>
                      {requesting ? "Submitting..." : "Submit"}
                    </Button>
                  </CardContent>
                </Card>
              </div>
            </div>
          </form>
        </FormProvider>

        {/* Dialog Component */}
        {
          activeModal === "successDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Product Added {orderID && `to Order`}</AlertDialogTitle>
                <AlertDialogDescription>
                  The product has been added {orderID && `to Order`} successfully
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel onClick={resetForm}>Add Another</AlertDialogCancel>
                <AlertDialogAction onClick={()=>{
                  if(orderID) {
                    navigate(`${process.env.PUBLIC_URL}/orders/details/${orderID}`);
                    return;
                  }

                  navigate(`${process.env.PUBLIC_URL}/products/details/${productID}`);
                }}>View Product</AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        { 
          activeModal === "addVariantTypeDialog" &&
          <AddVariantTypeDialog isOpen={true} onClose={() => toggleModal(null)}
            variantTypes={variantTypes}
            setVariantTypes={setVariantTypes}
            variants={variants}
            setMainFormValue={setValue}
            setVariants={setVariants}
            productName={getValues("product_name")}
            unitCost={getValues("cost")}
          />
        }

        { 
          activeModal === "editVariantTypeDialog" &&
          <EditVariantTypeDialog isOpen={true} onClose={() => toggleModal(null)}
            variantTypes={variantTypes}
            setVariantTypes={setVariantTypes}
            variants={variants}
            setMainFormValue={setValue}
            editVariantTypeIndex={editVariantTypeIndex}
            setVariants={setVariants}
            productName={getValues("product_name")}
            unitCost={getValues("cost")}
          />
        }

      </main>
    </React.Fragment>
  )
}
