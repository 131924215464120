import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Bell,
  CheckCheck,
  FileBox,
  HandCoins,
  ListCheck,
  PackageCheck,
  PackageOpen,
  PackageSearch,
  Search,
  ShoppingCart,
  TriangleAlert,
} from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { 
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from "../../components/ui/table";
import { Button } from "../../components/ui/button";
import Head from "../../layouts/Head";
import { Badge } from "../../components/ui/badge";
import axios from "axios";
import { baseURLs } from "../../misc/constants";
import { Skeleton, TableSkeleton } from "../../components/ui/skeleton";
import { useNavigate } from "react-router-dom";
import { NoResults } from "../../components/ui/alert";
import moment from "moment";
import { getAxiosHeaders, getQueryParams, OrderStatus } from "../../misc/utils";
import { Label } from "../../components/ui/label";
import { Input } from "../../components/ui/input";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../../components/ui/dialog";
import { PaginationWithOnclick } from "../../components/PaginationWithOnClick";
import { Checkbox } from "../../components/ui/checkbox";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "../../components/ui/alert-dialog";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../components/ui/dropdown-menu";
import { DotsVerticalIcon, QuestionMarkIcon } from "@radix-ui/react-icons";

export const Orders = () => {
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [isLoadingStats, setIsLoadingStats] = useState(true);
  const [stats, setStats] = useState([
    { name: "Requested", icon: FileBox, count: 0, status: "requested" },
    { name: "Pending Approval", icon: ListCheck, count: 0, status: "pending_approval" },
    { name: "Pending Payment", icon: HandCoins, count: 0, status: "pending_payment" },
    { name: "Processing", icon: PackageSearch, count: 0, status: "processing" },
    { name: "On Hold", icon: TriangleAlert, count: 0, status: "on_hold" },
    { name: "Completed", icon: PackageCheck, count: 0, status: "completed" },
  ]);
  const [tableData, setTableData] = useState({
    total_records: 0,
    data: []
  });
  const [totalPages, setTotalPages] = useState(1);
  const [hasFilters, setHasFilters] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState({    
    page: 1,
    customer: '',
    phone_number: '',
    order_number: '',
    order_status: '',
    order_type: '',
    start_date: '',
    end_date: '',
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [activeModal, setActiveModal] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const navigate = useNavigate();

  const onInputChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const onSelectChange = (value, name) => {
    setFilters({ ...filters, [name]: value });
  };

  const resetFilter = () => {
    let params = {
      page: 1,
      customer: '',
      phone_number: '',
      order_number: '',
      order_status: '',
      order_type: '',
      start_date: '',
      end_date: '',
    };
  
    toggleModal('filterModal');
    setHasFilters(false);
    setFilters({ ...params });
    getOrders(params);
  }

  const filterOrders = () => {
    toggleModal('filterModal');
    setHasFilters(true);
    let params = filters;
    params.page = 1;
    setFilters({...params});
    getOrders(filters);
  }

  // Toggle "select all" functionality
  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      const allRowIds = tableData.data.filter(order => order.status !== "cancelled").map(order => order.order_id);
      setSelectedRows([...allRowIds]);
      setCheckAll(true);
    } else {
      // If unchecked, clear all selected rows
      setSelectedRows([]);
      setCheckAll(false);
    }
  };

  // Toggle individual row selection
  const handleRowSelect = (order_id) => {
    if (selectedRows.includes(order_id)) {
      // Remove row from selection if already selected
      let _selectRows = selectedRows.filter((row) => row !== order_id);
      setSelectedRows([..._selectRows]);
    } else {
      // Add row to selection if not already selected
      setSelectedRows([...selectedRows, order_id]);
    }
  };

  const currentUrl = (filters) => {
    let customer_name = filters.customer.length > 0 ? `&cs=${filters.customer}` : ``;
    let phone_number = filters.phone_number.length > 0 ? `&pn=${filters.phone_number}` : ``;
    let order_number = filters.order_number.length > 0 ? `&on=${filters.order_number}` : ``;
    let order_status = filters.order_status !== null ? `&st=${filters.order_status}` : `all`;
    let order_type = filters.order_type !== null ? `&t=${filters.order_type}` : `all`;
    let date_range = filters.start_date !== null && filters.end_date !== null ? `&r=${filters.start_date},${filters.end_date}` : ``;
    
    if( (customer_name !== '' ||
      phone_number !== '' ||
      order_number !== '' ||
      filters.order_status !== 'all' ||
      filters.order_type !== 'all' ||
      date_range !== '' ) && !hasFilters) {
      setHasFilters(true)
    }

    setFilters({...filters});

    let params = `${customer_name}${phone_number}${order_number}${order_status}${order_type}${date_range}`;
    let url = `${process.env.PUBLIC_URL}${window.location.pathname}?p=${filters.page}${params}`;
    navigate(url, { replace: true })
  }

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getOrders(params)
  }

  const getOrdersStats = () => {
    setIsLoadingStats(true);
    axios.get(baseURLs.API_URL + `/orders/stats`, {
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      let responseInfo = response.data;
      if (response.status === 200) {
        setStats([
          { name: "Requested", icon: FileBox, count: responseInfo.data.requested, status: "requested" },
          { name: "Pending Approval", icon: ListCheck, count: responseInfo.data.pending_approval, status: "pending_approval" },
          { name: "Pending Payment", icon: HandCoins, count: responseInfo.data.pending_payment, status: "pending_payment" },
          { name: "Processing", icon: PackageSearch, count: responseInfo.data.processing, status: "processing" },
          { name: "On Hold", icon: TriangleAlert, count: responseInfo.data.on_hold, status: "on_hold" },
          { name: "Completed", icon: PackageCheck, count: responseInfo.data.completed, status: "completed" },
        ])

      } else {
        setStats([
          { name: "Requested", icon: FileBox, count: 0, status: "requested" },
          { name: "Pending Approval", icon: ListCheck, count: 0, status: "pending_approval" },
          { name: "Pending Payment", icon: HandCoins, count: 0, status: "pending_payment" },
          { name: "Processing", icon: PackageSearch, count: 0, status: "processing" },
          { name: "On Hold", icon: TriangleAlert, count: 0, status: "on_hold" },
          { name: "Completed", icon: PackageCheck, count: 0, status: "completed" },
        ])
      }

      setIsLoadingStats(false);
            
    }).catch((error) => {
      console.log({error})
      try{
        let errorResponse = error.response?.data ?? '';

        if(error.response.status === 401){
          navigate(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          navigate(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          navigate(`${process.env.PUBLIC_URL}/unauthorized`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setIsLoadingStats(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
      }
    });
  }

  const getOrders = (filters) => {
    axios.get(baseURLs.API_URL + "/orders", {
      headers: getAxiosHeaders().headers,
      params: {
        page: filters.page,
        customer: filters.customer,
        phone_number: filters.phone_number,
        order_number: filters.order_number,
        order_status: filters.order_status,
        order_type: filters.order_type,
        start_date: filters.start_date,
        end_date: filters.end_date,
      }
    })
    .then((response) => {
      let responseInfo = response.data;
      if (response.status === 200) {
        setTableData({
          total_records: responseInfo.data.total_records,
          data: responseInfo.data.orders,
        });

        setTotalPages(Math.ceil(responseInfo.data.total_records / 10));
      } else {
        setTableData({
          data: []
        })
      }

      currentUrl(filters);
      setLoadingOrders(false);
            
    }).catch((error) => {
      try{
        let errorResponse = error.response?.data ?? '';

        if(error.response.status === 401){
          navigate(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          navigate(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          navigate(`${process.env.PUBLIC_URL}/unauthorized`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoadingOrders(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
      }
    });
  }

  // Cancel all selected rows
  const handleCancelSelected = () => {
    if (selectedRows.length === 0) {
        setErrorMessage(`Select at least one order to cancel`);
        toggleModal('errorDialog');
    } else {
        toggleModal('deleteDialog');
    }
  };

  const handleSingleCancel = (orderIDs) => {
    handleCancelSelected([orderIDs]);
  };

  const confirmCancel = () => {
    checkAll ? cancelOrdersByFilter() : cancelOrdersByIDs();    
  };

  const cancelOrdersByIDs = () => {
    setRequesting(true);

    let _checkedBoxes = selectedRows;

    axios.delete(baseURLs.API_URL + "/orders/by-ids", {
      headers: getAxiosHeaders().headers,
      data: {
        orders: JSON.stringify(_checkedBoxes),
      }
    })
    .then((response) => {
      let responseInfo = response.data;
      let successfulIDs = responseInfo.data.successful_ids;
      let failedIDs = responseInfo.data.failed_ids;

      if(successfulIDs.length > 0 && failedIDs.length === 0){
        getOrders(filters);        
        toggleModal('successDialog');

      } else if(successfulIDs.length > 0 && failedIDs.length > 0) {
        getOrders(filters);
        setErrorMessage(`${successfulIDs.length} ${successfulIDs.length > 1 ? `shipments` : `shipment`} were successfully deleted and ${failedIDs.length} ${failedIDs.length > 1 ? `shipments` : `shipment`} failed to get deleted`);
        toggleModal('errorDialog');

      } else {
        setErrorMessage(`Error deleting ${failedIDs.length > 1 ? `shipments` : `shipment`}. The selected ${failedIDs.length > 1 ? `shipments were` : `shipment was`} not found.`);
        toggleModal('errorDialog');
      }

      setSelectedRows([]);
      setCheckAll(false);
      setRequesting(false);
    }).catch((error) => {
      console.log({error})
      try{
        let errorResponse = error.response?.data ?? '';

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('errorDialog');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
      setRequesting(false);
    });
  }

  const cancelOrdersByFilter = () => {
    setRequesting(false);

    axios.delete(baseURLs.API_URL + "/orders/by-filters", {
      headers: getAxiosHeaders().headers,
      data: {
        customer: filters.customer,
        phone_number: filters.phone_number,
        order_number: filters.order_number,
        order_status: filters.order_status,
        order_type: filters.order_type,
        start_date: filters.start_date,
        end_date: filters.end_date,
      }
    })
    .then((response) => {
      getOrders(filters);
      toggleModal('successDialog');
      setCheckAll(false);
      setRequesting(false);
    }).catch((error) => {
      console.log({error})
      try{
        let errorResponse = error.response?.data ?? '';

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('errorDialog');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
      setRequesting(false);
    });
  }

  const allSelected = selectedRows.length === tableData.data.filter(order => order.status !== "cancelled").length;
  
  useEffect(() => {
    setCheckAll(allSelected)
  },[allSelected])

  useEffect(() => {
    let url_string = window.location.href;
    let url = new URL(url_string);
    let queryParams = getQueryParams(url);

    let page = queryParams.hasOwnProperty('p') ? queryParams.p : 1;
    let customer_name = queryParams.hasOwnProperty('cs') ? queryParams.cs : '';
    let phone_number = queryParams.hasOwnProperty('pn') ? queryParams.pn : '';
    let order_number = queryParams.hasOwnProperty('on') ? queryParams.on : '';
    let order_status = queryParams.hasOwnProperty('st') ? queryParams.st : 'all';
    let order_type = queryParams.hasOwnProperty('t') ? queryParams.t : 'all';
    let date_range = queryParams.hasOwnProperty('r') ? queryParams.r : null;
    let startDate = null;
    let endDate = null;

    if(date_range !== null){
      let rangeSplit = date_range.split(',');
      startDate = new Date(rangeSplit[0]);
      endDate = new Date(rangeSplit[1]);
      setStartDate(startDate);
      setEndDate(endDate);
    }

    let params = filters;
    params.page = page;
    params.customer = customer_name;
    params.phone_number = phone_number;
    params.order_number = order_number;
    params.order_status = order_status;
    params.order_type = order_type;
    params.start_date = startDate;
    params.end_date = endDate;

    getOrders(params);
    getOrdersStats();
  }, [])

  return (
    <React.Fragment>
      <Head title="Orders" />
      <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6 lg:px-20">
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-semibold md:text-2xl">Orders</h1>
          <Link to="/orders/add"><Button>Create Custom Order</Button></Link>
        </div>

        {
          isLoadingStats ?
          <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-3">
            <Skeleton className="h-14 rounded-md" />
            <Skeleton className="h-14 rounded-md" />
            <Skeleton className="h-14 rounded-md" />
            <Skeleton className="h-14 rounded-md" />
            <Skeleton className="h-14 rounded-md" />
            <Skeleton className="h-14 rounded-md" />
          </div>
          :
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 gap-2 mb-4">
            {stats.map((stat) => (
              <Button
                key={stat.name}
                variant={filters.order_status === stat.status ? "default" : "outline"}
                className="h-14 px-4 flex items-center justify-between"
                onClick={() => {
                  let _filters = filters;
                  _filters.order_status = stat.status;

                  getOrders(_filters);
                }}
              >
                <div className="flex items-center">
                  <stat.icon className="h-4 w-4 mr-2 flex-shrink-0" />
                  <span className="text-xs font-medium truncate">{stat.name}</span>
                </div>
                <span className="text-sm font-bold ml-2">{stat.count}</span>
              </Button>
            ))}
          </div>

        }
        
        {
          loadingOrders ?
          <Card x-chunk="dashboard-05-chunk-3">
            <TableSkeleton />
          </Card>
          :
          <Card x-chunk="dashboard-05-chunk-3">
            <CardHeader className="px-7 flex-row justify-between">
              <CardTitle className="text-lg">{tableData.total_records} {tableData.total_records > 1 ? `Orders` : `Order`}</CardTitle>
              <Button variant="outline" onClick={() => toggleModal("filterModal")}><Search className="w-4 h-4 mr-2"  />Filter</Button>
            </CardHeader>
            <CardContent>
              {
                tableData.data.length === 0 ?
                <NoResults message="No orders found" />
                :
                <>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>
                          <Checkbox checked={allSelected} onCheckedChange={handleSelectAll} />
                        </TableHead>
                        <TableHead># Order ID</TableHead>
                        <TableHead>Customer</TableHead>
                        <TableHead className="hidden sm:table-cell">
                          Status
                        </TableHead>
                        <TableHead className="hidden sm:table-cell">
                          Type
                        </TableHead>
                        <TableHead className="hidden sm:table-cell">
                          Total
                        </TableHead>
                        <TableHead className="hidden sm:table-cell">
                          Date
                        </TableHead>
                        <TableHead>
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button variant="ghost">
                                <DotsVerticalIcon className="h-5 w-5" />
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                              <DropdownMenuItem className="text-red-500" onClick={handleCancelSelected}>
                                Cancel Selected
                              </DropdownMenuItem>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {
                        tableData.data.map((data, index) => {
                          const isSelected = selectedRows.includes(data.order_id);

                          return (
                            <TableRow key={index} className="cursor-pointer">
                              <TableCell>
                                {
                                  data.status !== 'cancelled' &&
                                  <Checkbox
                                    checked={isSelected}
                                    onCheckedChange={() => handleRowSelect(data.order_id)}
                                  />
                                }
                              </TableCell>
                              <TableCell onClick={() => navigate(`${process.env.PUBLIC_URL}/orders/details/${data.order_id}`)}>
                                #{data.order_id}
                              </TableCell>
                              <TableCell onClick={() => navigate(`${process.env.PUBLIC_URL}/orders/details/${data.order_id}`)}>
                                <div className="font-medium">
                                  <div className="font-medium">{data.customer_name}</div>
                                  <div className="hidden text-sm text-muted-foreground md:inline">
                                    {data.customer_phone_number}
                                  </div>
                                </div>
                                <div className="hidden text-sm text-muted-foreground md:inline">
                                {data.phone_number}
                                </div>
                              </TableCell>
                              <TableCell className="hidden sm:table-cell capitalize font-bold">
                                <OrderStatus status={data.status} />
                              </TableCell>
                              <TableCell className="hidden sm:table-cell">
                                <Badge className="text-xs capitalize" variant="secondary">
                                {`${data.order_type} order`}
                                </Badge>
                              </TableCell>
                              <TableCell className="hidden md:table-cell">
                                GHS {data.total_amount}
                              </TableCell>
                              <TableCell className="hidden md:table-cell">
                                {moment(data.updated_at).format("Do MMM YYYY hh:mm a")}
                              </TableCell>
                              <TableCell>
                                <DropdownMenu>
                                  <DropdownMenuTrigger asChild>
                                    <Button variant="ghost">
                                      <DotsVerticalIcon className="h-5 w-5" />
                                    </Button>
                                  </DropdownMenuTrigger>
                                  <DropdownMenuContent>
                                    <DropdownMenuItem onClick={() => navigate(`${process.env.PUBLIC_URL}/orders/details/${data.order_id}`)}>
                                      View Details
                                    </DropdownMenuItem>
                                    <hr/>
                                    <DropdownMenuItem className="text-red-500" onClick={() => handleSingleCancel(data.order_id)}>
                                      Cancel
                                    </DropdownMenuItem>
                                  </DropdownMenuContent>
                                </DropdownMenu>
                              </TableCell>
                            </TableRow>
                          )
                        })
                      }
                      
                    </TableBody>
                  </Table>
                  <div className="mt-5 justify-center">
                    <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />
                  </div>
                </>
              }
            </CardContent>
          </Card>
        }

        {
          activeModal === 'filterModal' &&
          <Dialog open={true} onOpenChange={() => toggleModal(null)}>
            <DialogContent>
              <DialogHeader className="mb-4">
                <DialogTitle>Filter Orders</DialogTitle>
              </DialogHeader>
                <div className="space-y-4">
                  <div className="grid gap-2">
                    <Label htmlFor="customer">Customer Name</Label>
                    <Input
                      id="customer"
                      name="customer"
                      type="text"
                      placeholder="Enter a customer name"
                      onChange={onInputChange}
                      defaultValue={filters.customer}
                    />
                  </div>

                  <div className="grid gap-2">
                    <Label htmlFor="phone_number">Phone Number</Label>
                    <Input
                      id="phone_number"
                      name="phone_number"
                      type="text"
                      placeholder="Enter a phone number"
                      onChange={onInputChange}
                      defaultValue={filters.phone_number}
                    />
                  </div>

                  <div className="grid gap-2">
                    <Label htmlFor="order_number">Order ID</Label>
                    <Input
                      id="order_number"
                      name="order_number"
                      type="text"
                      placeholder="Enter a order ID"
                      onChange={onInputChange}
                      defaultValue={filters.order_number}
                    />
                  </div>

                  <div className="grid gap-2">
                    <Label htmlFor="order_status">Order Status</Label>
                    <select 
                      name="category"
                      className="rounded-md border border-gray-300 p-2"
                      onChange={(e) => onSelectChange(e.target.value, "order_status")} 
                      value={filters.order_status}>
                      <option key="all" value="all">All</option>
                      <option key="requested" value="requested">Requested</option>
                      <option key="pending_approval" value="pending_approval">Pending Approval</option>
                      <option key="pending_payment" value="pending_payment">Pending Payment</option>
                      <option key="ordered" value="ordered">Ordered</option>
                      <option key="processing" value="processing">Processing</option>
                      <option key="on_hold" value="on_hold">On Hold</option>
                      <option key="completed" value="completed">Completed</option>
                    </select>
                  </div>

                  <div className="grid gap-2">
                    <Label htmlFor="order_type">Order Type</Label>
                    <select 
                      name="category"
                      className="rounded-md border border-gray-300 p-2"
                      onChange={(e) => onSelectChange(e.target.value, "order_type")} 
                      value={filters.order_type}>
                      <option key="all" value="all">All</option>
                      <option key="deal" value="deal">Deal</option>
                      <option key="custom" value="custom">Custom</option>
                    </select>
                  </div>
                </div>

                {/* Dialog Footer */}
                <DialogFooter>
                  <Button onClick={(ev) => { ev.preventDefault(); filterOrders();} }>Apply Filter</Button>
                  {
                    hasFilters &&
                    <Button className="ms-3" variant="secondary" onClick={(ev) => { ev.preventDefault(); resetFilter();} }>
                      Reset Filter
                    </Button>
                  }
                </DialogFooter>
            </DialogContent>
          </Dialog>
        }

        {
          activeModal === "deleteDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>
                  {selectedRows.length === 1 ? "Cancel Order" : "Cancel Selected Orders"}
                </AlertDialogTitle>
                <AlertDialogDescription>
                  {selectedRows.length === 1
                    ? "Are you sure you want to cancel this order? This action cannot be undone."
                    : "Are you sure you want to cancel all selected orders? This action cannot be undone."}
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel onClick={() => toggleModal(null)}>Cancel</AlertDialogCancel>
                {
                  requesting ?
                  <AlertDialogAction disabled>Cancelling...</AlertDialogAction>
                  :
                  <AlertDialogAction className="bg-red-600 text-white hover:bg-red-700" onClick={confirmCancel}>Confirm</AlertDialogAction>
                }
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === "successDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Deleted Successfully</AlertDialogTitle>
                <AlertDialogDescription>
                  Shipment(s) deleted successfully
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === "errorDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal("errorDialog")}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Error</AlertDialogTitle>
                <AlertDialogDescription>
                  {errorMessage}
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }
      </main>
    </React.Fragment>
  )
}
