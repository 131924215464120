import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  AlertCircle,
  Bell,
  CircleX,
  Cross,
} from "lucide-react"
import { Button, GoBackButton } from "../../components/ui/button";
import Head from "../../layouts/Head";
import { useDropzone } from 'react-dropzone';
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card";
import { FormProvider, useForm } from "react-hook-form";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Textarea } from "../../components/ui/textarea";
import { getAxiosHeaders, getAxiosUploadHeaders } from "../../misc/utils";
import axios from "axios";
import { baseURLs } from "../../misc/constants";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";
import { FormSkeleton } from "../../components/ui/skeleton";

export const EditCustomOrder = () => {
  const { orderID } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [files, setFiles] = useState([]);
  const [oldFiles, setOldFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [imagesError, setImagesError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Initialize the form methods
  const methods = useForm({
    defaultValues: {
      customer_name: "",
      phone_number: "",
      note: ""
    },
  });

  const { handleSubmit, control, formState: { errors }, reset, setError } = methods;

  const onSubmit = (data) => {
    setRequesting(true);
    setErrorMessage('');
    setImagesError('');
  
    const formData = new FormData();
  
    files.forEach(file => {
      formData.append('product_media', file); // Append files to formData
    });
    formData.set("customer_name", data.customer_name);
    formData.set("phone_number", data.phone_number);
    formData.set("note", data.note);
    formData.set("existing_media", JSON.stringify(oldFiles));
  
    axios.put(baseURLs.API_URL + `/orders/custom/${orderID}`, formData, getAxiosUploadHeaders())
      .then((response) => {
        let responseInfo = response.data;
  
        navigate(`${process.env.PUBLIC_URL}/orders/details/${orderID}`);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const responseErrors = error.response.data.errors;
          setErrorMessage(error.response.data.error);
  
          // Check for specific field errors and set them
          if (responseErrors.customer_name) {
            setError('customer_name', {
              type: 'server',
              message: responseErrors.customer_name
            });
          }
  
          if (responseErrors.phone_number) {
            setError('phone_number', {
              type: 'server',
              message: responseErrors.phone_number
            });
          }
  
          if (responseErrors.note) {
            setError('note', {
              type: 'server',
              message: responseErrors.note
            });
          }
  
          if (responseErrors.product_images) {
            setImagesError(responseErrors.product_images);
          }
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  };
  
  const getOrderInfo = () => {
    setLoading(true);
    axios.get(`${baseURLs.API_URL}/orders/${orderID}`, {
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      let responseInfo = response.data;
      if (response.status === 204) {
        navigate(`${process.env.PUBLIC_URL}/not-found`);
        return;
      }

      let orderInfo = responseInfo.data.order_info;
      reset({
        customer_name: orderInfo.customer_name,
        phone_number: orderInfo.customer_phone_number,
        note: orderInfo.customer_note
      })

      setOldFiles([...orderInfo.images]);
      setLoading(false);
            
    }).catch((error) => {
      try{
        let errorResponse = error.response?.data ?? '';

        if(error.response.status === 401){
          navigate(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          navigate(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          navigate(`${process.env.PUBLIC_URL}/unauthorized`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
      }
    });
  }

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach(file => {
      setFiles(prevFiles => [...prevFiles, Object.assign(file, { preview: URL.createObjectURL(file) })]);      
    });
  }, []);

  const removeFile = (fileToRemove) => {
    setFiles(prevFiles => prevFiles.filter(file => file !== fileToRemove));
    URL.revokeObjectURL(fileToRemove.preview);
  };

  const removeOldFile = (fileToRemove) => {
    setOldFiles(prevFiles => prevFiles.filter(file => file !== fileToRemove));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*,video/*',
  });

  useEffect(() => {
    return () => {
      files.forEach(file => URL.revokeObjectURL(file.preview));
    };
  }, [files]);

  useEffect(() => {
    getOrderInfo();
  }, [])

  return (
    <React.Fragment>
      <Head title="Edit Order" />
      <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6 lg:px-20">
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-semibold md:text-2xl">Edit Custom Order</h1>
          <GoBackButton fallbackRoute={`${process.env.PUBLIC_URL}/orders`} />
        </div>
        {
          loading ?
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-5 lg:gap-6">
            <div className="grid lg:col-span-3 lg:col-start-2 gap-8">
              <FormSkeleton />
            </div>
          </div>
          :
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-5 lg:gap-6">
                <div className="grid lg:col-span-3 lg:col-start-2 gap-8">
                  {
                    errorMessage &&
                    <Alert variant="destructive">
                      <AlertCircle className="h-4 w-4" />
                      <AlertTitle>Error</AlertTitle>
                      <AlertDescription>
                        {errorMessage}
                      </AlertDescription>
                    </Alert>
                  }
                  <Card>
                    <CardHeader className="pb-3"> 
                      <CardTitle className="text-lg">Customer Info</CardTitle>
                    </CardHeader>
                    <CardContent className="grid gap-6">
                      {/* Customer Field */}
                      <FormField
                        name="customer_name"
                        control={control}
                        rules={{
                          required: "Customer name is required",
                        }}
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Customer Name</FormLabel>
                            <FormControl>
                              <Input
                                {...field}
                                id="customer_name"
                                type="text"
                                placeholder="eg. Nana Kojo"
                                required
                              />
                            </FormControl>
                            {errors.phone_number && (
                              <FormMessage>{errors.phone_number.message}</FormMessage>
                            )}
                          </FormItem>
                        )}
                      />

                      {/* Phone number Field */}
                      <FormField
                        name="phone_number"
                        control={control}
                        rules={{
                          required: "Phone number is required",
                        }}
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Phone Number</FormLabel>
                            <FormControl>
                              <Input
                                {...field}
                                id="phone_number"
                                type="text"
                                placeholder="eg. 0201234567"
                                required
                              />
                            </FormControl>
                            {errors.phone_number && (
                              <FormMessage>{errors.phone_number.message}</FormMessage>
                            )}
                          </FormItem>
                        )}
                      />

                    </CardContent>
                  </Card>
                  <Card>
                    <CardHeader className="pb-3"> 
                      <CardTitle className="text-lg">Order Info</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <FormLabel>Upload Product Images/Videos</FormLabel>
                      <div {...getRootProps()} className={`mt-3 border-2 border-dashed p-6 rounded-md ${isDragActive ? 'border-blue-500 bg-blue-100' : 'border-gray-300'}`}>
                        <input {...getInputProps()} />
                        <p className="text-center text-gray-500">Drag & drop files here, or click to select</p>
                      </div>

                      <div className="mt-4 grid grid-cols-2 sm:grid-cols-3 gap-4">
                        {files.map((file, index) => (
                          <div key={index} className="relative">
                            {file.type.startsWith("image/") ? (
                              // Display image
                              <img
                                src={file.preview}
                                alt={file.name}
                                className="object-cover w-full h-40 rounded-md"
                              />
                            ) : file.type.startsWith("video/") ? (
                              // Display video
                              <video
                                src={file.preview}
                                className="object-cover w-full h-40 rounded-md"
                                controls
                              />
                            ) : null}
                            <button onClick={() => removeFile(file)} className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1"><CircleX/></button>
                          </div>
                        ))}
                      </div>
                      <hr className="my-4"/>
                      <FormLabel>Existing Images/Videos</FormLabel>
                      <div className="mt-4 grid grid-cols-2 sm:grid-cols-3 gap-4">
                        {oldFiles.map((file, index) => (
                          <div key={index} className="relative">
                            {file.type === "image" ? (
                              // Display image
                              <img
                                src={file.url}
                                className="object-cover w-full h-40 rounded-md"
                              />
                            ) : file.type === "video" ? (
                              // Display video
                              <video
                                src={file.url}
                                className="object-cover w-full h-40 rounded-md"
                                controls
                              />
                            ) : null}
                            <button onClick={() => removeOldFile(file)} className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1"><CircleX/></button>
                          </div>
                        ))}
                      </div>

                      {/* Note Field */}
                      <div className="mt-5">
                        <FormField
                          name="note"
                          control={control}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Note</FormLabel>
                              <FormControl>
                                <Textarea
                                  {...field}
                                  id="note"
                                  placeholder="eg. I will like..."
                                />
                              </FormControl>
                              {errors.note && (
                                <FormMessage>{errors.note.message}</FormMessage>
                              )}
                            </FormItem>
                          )}
                        />
                      </div>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardContent className="pt-6">
                      <Button type="submit" className="w-full" disabled={requesting}>
                        {requesting ? "Submitting..." : "Submit"}
                      </Button>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </form>
          </FormProvider>
        }
      </main>
    </React.Fragment>
  )
}
