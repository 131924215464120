import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  AlertCircle,
  Bell,
  ChevronDown,
  MoreHorizontal,
  MoreVertical,
  Plus,
} from "lucide-react"
import { Button, GoBackButton } from "../../components/ui/button";
import Head from "../../layouts/Head";
import { getAxiosHeaders, getInvoiceStatusColor, OrderStatus } from "../../misc/utils";
import { baseURLs } from "../../misc/constants";
import axios from "axios";
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card";
import { Skeleton } from "../../components/ui/skeleton";
import moment from "moment";
import { Badge } from "../../components/ui/badge";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../../components/ui/dialog";
import { Label } from "../../components/ui/label";
import { RadioGroup, RadioGroupItem } from "../../components/ui/radio-group";
import { 
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from "../../components/ui/table";
import { Input } from "../../components/ui/input";
import { Alert, AlertDescription, AlertTitle, NoResults } from "../../components/ui/alert";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator } from "../../components/ui/dropdown-menu";
import { Checkbox } from "../../components/ui/checkbox";
import { PaginationWithOnclick } from "../../components/PaginationWithOnClick";
import { CustomOrderedShipmentDialog, DealOrderedShipmentDialog, EditSourcedProductQuantityDialog, UpdateInvoiceBalanceDialog } from "../components/OrderUpdateForms";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "../../components/ui/alert-dialog";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../components/ui/form";
import { ProductSelect, ProductVariantSelect } from "../components/ProductSelect";
import { FormProvider, useForm } from "react-hook-form";
import { Separator } from "../../components/ui/separator";
import { DotsVerticalIcon } from "@radix-ui/react-icons";

function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
}

export const TrackOrder = () => {
  const ITEMS_PER_PAGE = 10;
  const { orderTrackingNumber } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [orderInfo, setOrderInfo] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [activeModal, setActiveModal] = useState(null);
  
  const [selectedInvoice, setSelectedInvoice] = useState("");
  const [selectedCancelInvoice, setSelectedCancelInvoice] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSourcedProduct, setSelectedSourcedProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  // Initialize the form methods
  const methods = useForm({
    defaultValues: {
      product_id: "",
      moq: ""
    },
  });

  const { handleSubmit, control, formState: { errors }, setError } = methods;


  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentImage('');
  };

  const filteredProducts = orderInfo?.products?.filter((product) =>
    Object.values(product).some((value) =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  ) || [];

  const totalPages = Math.ceil(filteredProducts.length / ITEMS_PER_PAGE)
  const paginatedProducts = filteredProducts.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  )

  const handleSelectAll = (checked) => {
    if (checked) {
      setSelectedProducts(paginatedProducts.map((product) => product.order_product_id))
    } else {
      setSelectedProducts([])
    }
  }

  const handleSelectOrder = (orderProductId, checked) => {
    if (checked) {
      setSelectedProducts([...selectedProducts, orderProductId])
    } else {
      setSelectedProducts(selectedProducts.filter((id) => id !== orderProductId))
    }
  }

  const handleUpdateQuantity = (product) => {
    setSelectedSourcedProduct(product);
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
    setSelectedProducts([])
  }

  const getOrderInfo = () => {
    setLoading(true);
    axios.get(`${baseURLs.API_URL}/public/order-tracking/${orderTrackingNumber}`)
    .then((response) => {
      let responseInfo = response.data;
      if (response.status === 204) {
        navigate(`${process.env.PUBLIC_URL}/not-found`);
        return;
      }

      setOrderInfo(responseInfo.data.order_info);
      setSelectedStatus(responseInfo.data.order_info.status);
      setLoading(false);
            
    }).catch((error) => {
      try{
        let errorResponse = error.response?.data ?? '';

        if(error.response.status === 401){
          navigate(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          navigate(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          navigate(`${process.env.PUBLIC_URL}/unauthorized`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
      }
    });
  }

  useEffect(() => {
    if(!orderTrackingNumber){
      navigate(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    getOrderInfo();
  }, [])

  return (
    <React.Fragment>
      <Head title="Track Order" />
      <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6 lg:px-20">
        {
          loading ?
          <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
            <div className="md:col-span-5">
              <Skeleton className="h-[360px] rounded-xl" />
            </div>
            <div className="md:col-span-7">
              <Skeleton className="h-[360px] rounded-xl" />
            </div>
          </div>
          :
          <>
            <div className="flex items-center justify-between">
              <h1 className="text-lg font-semibold md:text-2xl">Order Details - {`#${orderInfo.order_id}`}</h1>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
              <div className="md:col-span-5 gap-8">
                <Card>
                  <CardHeader>
                    <CardTitle className="text-md">Customer Info</CardTitle>
                  </CardHeader>
                  <CardContent className="gap-8">
                    <div>
                      <span className="text-sm font-medium text-gray-500">Name:</span>
                      <p className="text-lg font-semibold text-gray-800">{orderInfo.customer_name}</p>
                    </div>
                    <div>
                      <span className="text-sm font-medium text-gray-500">Phone Number:</span>
                      <p className="text-lg font-semibold text-gray-800">{orderInfo.customer_phone_number}</p>
                    </div>
                  </CardContent>
                </Card>
                {
                  orderInfo.images.length > 0 &&
                  <Card className="mt-5">
                    <CardHeader>
                      <CardTitle className="text-md">Uploaded Images</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <div className="grid grid-cols-3 gap-4">
                        {/* Image thumbnails */}
                        {
                          orderInfo.images.map((image, index) => {
                            return (
                              <img src={image} key={index}
                                className="cursor-pointer w-24 h-24 object-cover rounded-md border border-gray-200"
                                onClick={() => openModal(image)}
                              />
                            )
                          })
                        }                      
                      </div>
                    </CardContent>
                  </Card>
                }

                <Card className="mt-5">
                  <CardHeader>
                    <CardTitle className="text-md">Invoices</CardTitle>
                  </CardHeader>
                  <CardContent>
                    {
                      orderInfo.invoices.length === 0 ?
                      <NoResults message="No invoice found" />
                      :
                      <div className="divide-y">
                        {orderInfo.invoices.map((invoice) => (
                          <div key={invoice.sale_id} className="py-4 flex flex-col sm:flex-row sm:items-start justify-between gap-4">
                            <div className="flex-grow">
                              <div className="flex items-center mb-2">
                                <h3 className="text-lg font-semibold">#{invoice.invoice_number}</h3>
                                <Badge className={`ml-2 ${getInvoiceStatusColor(invoice.status)}`}>{invoice.status}</Badge>
                                <Badge className="ml-2">{invoice.type}</Badge>
                              </div>
                              <div className="grid-cols-1 sm:grid-cols-2 text-sm">
                                <div className="flex items-center font-medium mb-2">
                                  {
                                    invoice.status === 'receipt' ?
                                    <span>Total: GHS {invoice.total_amount}</span>
                                    :
                                    <span>Total: GHS {invoice.amount_paid} / {invoice.total_amount}</span>
                                  }
                                </div>
                                <div className="flex items-center">
                                  {/* <Calendar className="h-4 w-4 mr-2 text-muted-foreground" /> */}
                                  <span>{moment(invoice.created_at).format("Do MMM YYYY hh:mm a")}</span>
                                </div>
                              </div>
                            </div>
                            <div className="flex items-center space-x-2">
                              <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                  <Button variant="ghost" size="sm">
                                    <MoreVertical className="h-4 w-4" />
                                  </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent align="end">
                                  <DropdownMenuItem onClick={() => window.open(invoice.invoice_link, '_blank')}>
                                    View Details
                                  </DropdownMenuItem>
                                </DropdownMenuContent>
                              </DropdownMenu>
                            </div>
                          </div>
                        ))}
                      </div>
                    }
                  </CardContent>
                </Card>

                {/* Modal for Image Pop-up */}
                {modalOpen && (
                  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="relative">
                      <img src={currentImage} alt="Selected" className="max-h-[500px] object-contain rounded-md" />
                      <button
                        onClick={closeModal}
                        className="absolute top-2 right-2 text-white text-3xl"
                      >
                        &times;
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="md:col-span-7">
                <div className="grid md:col-span-5 gap-8">
                  <Card>
                    <CardHeader>
                      <CardTitle className="text-md">Order Info</CardTitle>
                    </CardHeader>
                    <CardContent className="grid gap-6">
                      <div className="grid sm:grid-cols-2 gap-4">
                        <div>
                          <span className="text-sm font-medium text-gray-500">Order Type:</span>
                          <p className="text-md font-semibold text-gray-800">
                            <Badge className="capitalize" variant="secondary">
                              {`${orderInfo.order_type} order`}
                            </Badge>
                          </p>
                        </div>
                        <div>
                          <span className="text-sm font-medium text-gray-500">Order Tracking Number:</span>
                          <p className="text-md font-semibold text-gray-800">{orderInfo.order_tracking_number}</p>
                        </div>

                        <div>
                          <span className="text-sm font-medium text-gray-500">Service Cost:</span>
                          <p className="text-md font-semibold text-gray-800">GHS {orderInfo.service_cost}</p>
                        </div>
                        <div>
                          <span className="text-sm font-medium text-gray-500">Total Cost:</span>
                          <p className="text-md font-semibold text-gray-800">GHS {orderInfo.total_amount}</p>
                        </div>

                        <div>
                          <span className="text-sm font-medium text-gray-500">Order Status:</span>
                          <p className="text-md font-semibold text-gray-800">
                            <OrderStatus status={orderInfo.status} />
                          </p>
                          
                        </div>
                        <div>
                          <span className="text-sm font-medium text-gray-500">Created By:</span>
                          <p className="text-md font-semibold text-gray-800">{orderInfo.created_by}</p>
                        </div>
                        <div>
                          <span className="text-sm font-medium text-gray-500">Created:</span>
                          <p className="text-md font-semibold text-gray-800">{moment(orderInfo.created_at).format("Do MMM YYYY hh:mm a")}</p>
                        </div>
                      </div>
                      
                    </CardContent>
                  </Card>

                  {
                    orderInfo.status === 'requested' ?
                    <Card>
                      <CardHeader className="px-7 flex-row justify-between">
                        <CardTitle className="text-md content-center">{`${orderInfo.products.length} ${orderInfo.products.length > 1 ? `Sourced Products` : `Sourced Product`}`} </CardTitle>
                      </CardHeader>
                      
                      <CardContent>                      
                        <NoResults message="Sourced products will show here" />
                      </CardContent>
                    </Card>
                    :
                    <Card>
                      <CardHeader>
                        <CardTitle className="text-md">Products</CardTitle>
                      </CardHeader>
                      <CardContent>
                      {
                        orderInfo.products.length === 0 ?
                        <NoResults message="No product found" />
                        :
                        <>
                          <div className="flex justify-between items-center mb-4">
                            <Input
                              type="text"
                              placeholder="Search product..."
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              className="max-w-sm"
                            />
                          </div>
                          <Table>
                            <TableHeader>
                              <TableRow>
                              {/* <TableHead className="w-[50px]">
                                {
                                  orderInfo.status !== 'cancelled' &&
                                  <Checkbox
                                    checked={
                                      paginatedProducts.length > 0 &&
                                      selectedProducts.length === paginatedProducts.length
                                    }
                                    onCheckedChange={handleSelectAll}
                                  />
                                }
                                </TableHead> */}
                                <TableHead>Image</TableHead>
                                <TableHead>Product</TableHead>
                                <TableHead className="sm:table-cell">
                                  Status
                                </TableHead>
                                <TableHead className="sm:table-cell">
                                  Quantity
                                </TableHead>
                                <TableHead className="sm:table-cell">
                                  Total
                                </TableHead>
                                
                                {
                                  orderInfo.status === 'pending_approval' &&
                                  <TableHead className="sm:table-cell">
                                    <DotsVerticalIcon className="h-5 w-5" />
                                  </TableHead>
                                }
                              </TableRow>
                            </TableHeader>
                            <TableBody>
                              {
                                paginatedProducts.map((product, index) => {
                                  return (
                                    <TableRow key={index} className="cursor-pointer">
                                      {/* <TableCell>
                                        {
                                          orderInfo.status !== 'cancelled' &&
                                          <Checkbox
                                            checked={selectedProducts.includes(product.order_product_id)}
                                            onCheckedChange={(checked) =>
                                              handleSelectOrder(product.order_product_id, checked)
                                            }
                                          />
                                        }
                                        
                                      </TableCell> */}
                                      <TableCell className="hidden sm:table-cell">
                                        <img
                                          alt={product.product_name}
                                          className="aspect-square rounded-md object-cover"
                                          height="44"
                                          src={product.image}
                                          width="44"
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <div className="font-medium">
                                          <div className="font-medium">{product.product_name}</div>
                                          <div className="text-xs text-muted-foreground">
                                            GHS {product.unit_cost}
                                          </div>
                                        </div>
                                      </TableCell>
                                      <TableCell className="hidden sm:table-cell capitalize font-bold">
                                        <OrderStatus status={orderInfo.status === 'cancelled' ? 'cancelled' : 
                                          ['on_hold','processing','pending_payment','approved','pending_approval','requested'].includes(product.status) ? `` : product.status
                                        } />
                                        {
                                          (product.updated_by ?? product.created_by) && 
                                          <div><small>({product.updated_by ?? product.created_by})</small></div>                                  
                                        }
                                      </TableCell>
                                      <TableCell className="hidden md:table-cell">
                                        {product.quantity}
                                      </TableCell>
                                      <TableCell className="hidden md:table-cell">
                                        GHS {product.total_product_cost}
                                      </TableCell>

                                      {
                                        orderInfo.status === 'pending_approval' &&
                                        <TableCell>
                                          <DropdownMenu>
                                            <DropdownMenuTrigger asChild>
                                              <Button variant="ghost">
                                                <DotsVerticalIcon className="h-5 w-5" />
                                              </Button>
                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent>
                                              <DropdownMenuItem onClick={() => handleUpdateQuantity(product)}>
                                                Update Quantity
                                              </DropdownMenuItem>
                                            </DropdownMenuContent>
                                          </DropdownMenu>
                                        </TableCell>
                                      }
                                    </TableRow>
                                  )
                                })
                              }
                              
                            </TableBody>
                          </Table>
                          <div className="mt-5 justify-center">
                            <PaginationWithOnclick currentPage={currentPage} pageCount={totalPages} loadNextPage={handlePageChange} />
                          </div>
                        </>
                      }
                      </CardContent>
                    </Card>
                  }

                  
                </div>
              </div>
            </div>
          </>
        }
      </main>
    </React.Fragment>
  )
}
