import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  AlertCircle,
  ChevronDown,
  MoreHorizontal,
  Pencil,
  Plus,
} from "lucide-react"
import { FormProvider, useForm } from "react-hook-form";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../../components/ui/dialog";
import { Button, GoBackButton } from "../../components/ui/button";
import Head from "../../layouts/Head";
import { DealStatusBadge, getAxiosHeaders, OrderStatus } from "../../misc/utils";
import { baseURLs } from "../../misc/constants";
import axios from "axios";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "../../components/ui/alert-dialog";
import { Alert, AlertDescription, AlertTitle, NoResults } from "../../components/ui/alert";
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card";
import { Skeleton } from "../../components/ui/skeleton";
import moment from "moment";
import { 
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from "../../components/ui/table";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator } from "../../components/ui/dropdown-menu";
import { Badge } from "../../components/ui/badge";
import { Checkbox } from "../../components/ui/checkbox";
import { PaginationWithOnclick } from "../../components/PaginationWithOnClick";
import { DealOrderedShipmentDialog } from "../components/OrderUpdateForms";
import { DealProductVariantSelect } from "../components/ProductSelect";

export const DealDetails = () => {
  const ITEMS_PER_PAGE = 10;
  const { dealID } = useParams();
  const navigate = useNavigate();

  const [orderID, setOrderID] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [dealInfo, setDealInfo] = useState({});
  const [orders, setOrders] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [activeModal, setActiveModal] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  // Initialize the form methods
  const methods = useForm({
    defaultValues: {
      customer_name: "",
      phone_number: "",
      quantity: ""
    },
  });

  const { handleSubmit, control, formState: { errors }, reset, setError } = methods;

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const filteredOrders = orders.filter((order) =>
    Object.values(order).some((value) =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  )

  const totalPages = Math.ceil(filteredOrders.length / ITEMS_PER_PAGE)
  const paginatedOrders = filteredOrders.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  )

  const handleSelectAll = (checked) => {
    if (checked) {
      setSelectedOrders(paginatedOrders.map((order) => order.order_id))
    } else {
      setSelectedOrders([])
    }
  }

  const handleSelectOrder = (orderId, checked) => {
    if (checked) {
      setSelectedOrders([...selectedOrders, orderId])
    } else {
      setSelectedOrders(selectedOrders.filter((id) => id !== orderId))
    }
  }

  const handleBulkAction = (action) => {
    console.log(`Applying bulk action: ${action} to orders:`, selectedOrders)
    // Here you would typically call an API or perform some action
    // For now, we'll just log the action and selected orders
  }

  const handleSingleOrderAction = (action, orderId) => {
    console.log(`Applying action: ${action} to order:`, orderId)
    // Here you would typically call an API or perform some action
    // For now, we'll just log the action and order ID
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
    setSelectedOrders([])
  }

  const handleNewOrderSubmit = (data) => {

    setRequesting(true);
    setErrorMessage('');

    axios.post(baseURLs.API_URL + `/orders/deal/${dealID}`, {
      customer_name: data.customer_name,
      phone_number: data.phone_number,
      quantity: data.quantity,
      product_id: data.product_id,
    }, getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      setOrderID(responseInfo.data.order_id);
      getDealOrders();
      toggleModal("successDialog");
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const responseErrors = error.response.data.errors;
        setErrorMessage(error.response.data.error);

        // Check for specific field errors and set them
        if (responseErrors.customer_name) {
          setError('customer_name', {
            type: 'server',
            message: responseErrors.customer_name
          });
        }

        if (responseErrors.phone_number) {
          setError('phone_number', {
            type: 'server',
            message: responseErrors.phone_number
          });
        }

        if (responseErrors.quantity) {
          setError('quantity', {
            type: 'server',
            message: responseErrors.quantity
          });
        }
      }
    })
    .finally(() => {
      setRequesting(false);
    });
  }; 

  const confirmCancel = () => {
    setRequesting(true);

    let _checkedBoxes = selectedOrders;

    axios.delete(baseURLs.API_URL + "/orders/cancel/by-ids", {
      headers: getAxiosHeaders().headers,
      data: {
        orders: JSON.stringify(_checkedBoxes),
      }
    })
    .then((response) => {
      let responseInfo = response.data;
      let successfulIDs = responseInfo.data.successful_ids;
      let failedIDs = responseInfo.data.failed_ids;

      if(successfulIDs.length > 0 && failedIDs.length === 0){
        getDealOrders();        
        toggleModal('successCancelledDialog');

      } else if(successfulIDs.length > 0 && failedIDs.length > 0) {
        getDealOrders();
        setErrorMessage(`${successfulIDs.length} ${successfulIDs.length > 1 ? `orders` : `order`} were successfully deleted and ${failedIDs.length} ${failedIDs.length > 1 ? `orders` : `order`} failed to get deleted`);
        toggleModal('errorDialog');

      } else {
        setErrorMessage(`Error deleting ${failedIDs.length > 1 ? `orders` : `order`}. The selected ${failedIDs.length > 1 ? `orders were` : `order was`} not found.`);
        toggleModal('errorDialog');
      }

      setSelectedOrders([]);
      setRequesting(false);
    }).catch((error) => {
      console.log({error})
      try{
        let errorResponse = error.response?.data ?? '';

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('errorDialog');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
      setRequesting(false);
    });
  }

  const resetForm = () => {
    reset();
    toggleModal('addDealOrder');
  }

  const getDealInfo = () => {
    axios.get(`${baseURLs.API_URL}/deals/${dealID}`, {
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      let responseInfo = response.data;
      if (response.status === 204) {
        navigate(`${process.env.PUBLIC_URL}/not-found`);
        return;
      }

      setDealInfo(responseInfo.data.deal_info);
      setLoading(false);
            
    }).catch((error) => {
      try{
        let errorResponse = error.response?.data ?? '';

        if(error.response.status === 401){
          navigate(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          navigate(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          navigate(`${process.env.PUBLIC_URL}/unauthorized`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
      }
    });
  }

  const getDealOrders = () => {
    setSelectedOrders([]);
    
    axios.get(`${baseURLs.API_URL}/deals/orders/${dealID}`, {
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      let responseInfo = response.data;
      if (responseInfo.status === 200) {
        setOrders(responseInfo.data.orders);
      }
      
      setLoadingOrders(false);
            
    }).catch((error) => {
      try{
        let errorResponse = error.response?.data ?? '';

        if(error.response.status === 401){
          navigate(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          navigate(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          navigate(`${process.env.PUBLIC_URL}/unauthorized`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoadingOrders(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
      }
    });
  }

  useEffect(() => {
    if(!dealID){
      navigate(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    getDealInfo();
    getDealOrders();
  }, [])

  return (
    <React.Fragment>
      <Head title="Deal Details" />
      <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6 lg:px-20">
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-semibold md:text-2xl">Deal Details - {`#${dealID}`}</h1>
          <div className="flex gap-x-2">
            <Link to={`${process.env.PUBLIC_URL}/deals/edit/${dealID}`}>
                <Button><Pencil className="mr-2" /> Edit Deal</Button>
            </Link>
            <GoBackButton fallbackRoute={`${process.env.PUBLIC_URL}/deals`} />
          </div>
        </div>
        {
          loading ?
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-5 lg:gap-6">
            <div className="grid lg:col-span-3 lg:col-start-2 gap-8">
              <Skeleton className="h-[360px] rounded-xl" />
            </div>
          </div>
          :
          <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
            <div className="grid md:col-span-5 gap-8">
              <Card>
                <CardContent className="gap-8">
                  <div className="max-h-60 overflow-y-auto">
                    <div className="flex items-center gap-4 py-6 cursor-pointer" onClick={() => navigate(`${process.env.PUBLIC_URL}/products/details/${dealInfo.product_id}`)}>
                      <img
                        src={dealInfo.thumbnail}
                        alt={dealInfo.product_name}
                        className="w-20 h-20 rounded"
                      />
                      <div>
                        <p className="text-lg font-medium">{dealInfo.product_name}</p>
                        <p className="text-md text-gray-500">{`¥ ${dealInfo.unit_cost} ~ GHS ${dealInfo.ghs_cost} | MOQ: ${dealInfo.moq}`}</p>
                      </div>
                    </div>
                  </div>
                  <hr/>

                  <div className="grid grid-cols-2 gap-6 pt-6">
                    <div>
                      <span className="text-sm font-medium text-gray-500">Start Date</span>
                      <p className="text-lg font-semibold text-gray-800">{moment(dealInfo.start_date).format("Do MMM YYYY hh:mm a")}</p>
                    </div>
                    <div>
                      <span className="text-sm font-medium text-gray-500">End Date</span>
                      <p className="text-lg font-semibold text-gray-800">{moment(dealInfo.end_date).format("Do MMM YYYY hh:mm a")}</p>
                    </div>
                    
                  </div>

                  <div className="grid grid-cols-2 gap-6 pt-6">
                    <div className="py-2">
                      <span className="text-sm font-medium text-gray-500">Created By</span>
                      <p className="text-lg font-semibold text-gray-800">{dealInfo.created_by}</p>
                    </div>
                    <div className="py-2">
                      <span className="text-sm font-medium text-gray-500">Status</span>
                      <p className="text-lg font-semibold text-gray-800">
                        <DealStatusBadge status={dealInfo.status} />                        
                      </p>
                    </div>

                    <div className="py-2">
                      <span className="text-sm font-medium text-gray-500">Service Rate</span>
                      <p className="text-lg font-semibold text-gray-800">{dealInfo.service_rate}%</p>
                    </div>

                  </div>
                  <hr/>

                  {/* copy deal link here */}
                  <div className="pt-6">
                    <label className="block text-sm font-medium text-gray-500">Deal Link</label>
                    <div className="flex items-center mt-2">
                      <input
                        type="text"
                        value={`${dealInfo.deal_link}`}
                        readOnly
                        className="flex-1 px-4 py-2 text-gray-700 bg-gray-100 border border-gray-300 rounded-lg focus:outline-none"
                      />
                      <Button
                        className="ml-4"
                        onClick={() => {
                          navigator.clipboard.writeText(`${dealInfo.deal_link}`);
                          alert("Link copied to clipboard!");
                        }}
                      >
                        Copy
                      </Button>
                    </div>
                  </div>
                </CardContent>
              </Card>              
            </div>
            <div className="md:col-span-7">
              {
                loadingOrders ?
                <Skeleton className="h-[360px] rounded-xl" />
                :
                <Card>
                  <CardHeader className="px-7 flex-row justify-between">
                    <CardTitle className="text-md content-center">{`${orders.length} ${orders.length > 1 ? `Orders` : `Order`}`} </CardTitle>
                    <Button variant="outline" onClick={() => toggleModal("addDealOrder")}><Plus className="w-4 h-4 mr-2"  />Add an Order</Button>
                  </CardHeader>
                  <CardContent>
                  {
                    orders.length === 0 ?
                    <NoResults message="No orders found" />
                    :
                    <>
                      <div className="flex justify-between items-center mb-4">
                        <Input
                          type="text"
                          placeholder="Search orders..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          className="max-w-sm"
                        />
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button variant="outline" className="ml-auto" disabled={selectedOrders.length === 0}>
                              Bulk Actions <ChevronDown className="ml-2 h-4 w-4" />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end">
                            <DropdownMenuSeparator />
                            <DropdownMenuItem onClick={() => toggleModal("updateAsOrderedModal")}>
                              Update as Ordered
                            </DropdownMenuItem>
                            <DropdownMenuItem className="text-red-500" onClick={() => toggleModal("cancelOrderDialog")}>
                              Cancel Orders
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </div>
                      <Table>
                        <TableHeader>
                          <TableRow>
                          <TableHead className="w-[50px]">
                              <Checkbox
                                checked={
                                  paginatedOrders.length > 0 &&
                                  selectedOrders.length === paginatedOrders.length
                                }
                                onCheckedChange={handleSelectAll}
                              />
                            </TableHead>
                            <TableHead>#ID</TableHead>
                            <TableHead>Customer</TableHead>
                            <TableHead className="sm:table-cell">
                              Status
                            </TableHead>
                            <TableHead className="sm:table-cell">
                              Quantity
                            </TableHead>
                            <TableHead className="sm:table-cell">
                              Total
                            </TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {
                            paginatedOrders.map((order, index) => {
                              return (
                                <TableRow key={index} className="cursor-pointer">
                                  <TableCell>
                                    <Checkbox
                                      checked={selectedOrders.includes(order.order_id)}
                                      onCheckedChange={(checked) =>
                                        handleSelectOrder(order.order_id, checked)
                                      }
                                    />
                                  </TableCell>
                                  <TableCell onClick={() => navigate(`${process.env.PUBLIC_URL}/orders/details/${order.order_id}`)}>
                                    ORD{order.order_id}
                                  </TableCell>
                                  <TableCell onClick={() => navigate(`${process.env.PUBLIC_URL}/orders/details/${order.order_id}`)}>
                                    <div className="font-medium">
                                      <div className="font-medium">{order.customer_name}</div>
                                      <div className="text-xs text-muted-foreground">
                                        {order.customer_phone_number}
                                      </div>
                                    </div>
                                  </TableCell>
                                  <TableCell className="sm:table-cell capitalize font-bold">
                                    <OrderStatus status={order.status} />
                                    {
                                      (dealInfo.updated_by ?? dealInfo.created_by) && 
                                      <div><small>({dealInfo.updated_by ?? dealInfo.created_by})</small></div>                                  
                                    }
                                  </TableCell>
                                  <TableCell className="md:table-cell">
                                    {order.quantity}
                                  </TableCell>
                                  <TableCell className="md:table-cell">
                                    GHS {order.total_amount}
                                  </TableCell>
                                </TableRow>
                              )
                            })
                          }
                          
                        </TableBody>
                      </Table>
                      <div className="mt-5 justify-center">
                        <PaginationWithOnclick currentPage={currentPage} pageCount={totalPages} loadNextPage={handlePageChange} />
                      </div>
                    </>
                  }
                  </CardContent>
                </Card>
              }
            </div>
          </div>
        }

        {
          activeModal === 'addDealOrder' &&
          <Dialog open={true} onOpenChange={() => toggleModal(null)}>
            <DialogContent>
              <DialogHeader className="mb-4">
                <DialogTitle>Add Deal Order</DialogTitle>
              </DialogHeader>
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(handleNewOrderSubmit)}>
                    <div className="space-y-4">
                      {
                        errorMessage &&
                        <Alert variant="destructive">
                          <AlertCircle className="h-4 w-4" />
                          <AlertTitle>Error</AlertTitle>
                          <AlertDescription>
                            {errorMessage}
                          </AlertDescription>
                        </Alert>
                      }
                      
                      <FormField
                        name="customer_name"
                        control={control}
                        rules={{
                          required: "Customer name is required",
                        }}
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Customer Name</FormLabel>
                            <FormControl>
                              <Input
                                {...field}
                                id="customer_name"
                                type="text"
                                placeholder="eg. Kwame Ato"
                                required
                              />
                            </FormControl>
                            {errors.customer_name && (
                              <FormMessage>{errors.customer_name.message}</FormMessage>
                            )}
                          </FormItem>
                        )}
                      />

                      {/* product Field */}
                      <FormField
                        name="product_id"
                        control={control}
                        rules={{
                          required: "Product is required",
                        }}
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Product</FormLabel>
                            <FormControl>
                              <DealProductVariantSelect 
                                products={dealInfo.variants}
                                field={field}
                                onChange={(value) => field.onChange(value)}
                              />
                            </FormControl>
                            {errors.product_id && (
                              <FormMessage>{errors.product_id.message}</FormMessage>
                            )}
                          </FormItem>
                        )}
                      />

                      <div className="grid grid-cols-2 gap-6">
                        {/* Phone number Field */}
                        <FormField
                          name="phone_number"
                          control={control}
                          rules={{
                            required: "Phone number is required",
                          }}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Phone Number</FormLabel>
                              <FormControl>
                                <Input
                                  {...field}
                                  id="phone_number"
                                  type="text"
                                  placeholder="eg. 0201234567"
                                  required
                                />
                              </FormControl>
                              {errors.phone_number && (
                                <FormMessage>{errors.phone_number.message}</FormMessage>
                              )}
                            </FormItem>
                          )}
                        />                        

                        {/* Quantity Field */}
                        <FormField
                          name="quantity"
                          control={control}
                          rules={{
                            required: "Quantity is required",
                          }}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Quantity</FormLabel>
                              <FormControl>
                                <Input
                                  {...field}
                                  id="quantity"
                                  type="text"
                                  placeholder="eg. 3"
                                  required
                                />
                              </FormControl>
                              {errors.quantity && (
                                <FormMessage>{errors.quantity.message}</FormMessage>
                              )}
                            </FormItem>
                          )}
                        />
                      </div>
                    </div>
                    {/* Dialog Footer */}
                    <DialogFooter>
                      <Button type="submit" className="w-full mt-5" disabled={requesting}>
                        {requesting ? "Submitting..." : "Save and Send Invoice"}
                      </Button>
                    </DialogFooter>

                  </form>              
                </FormProvider>
            </DialogContent>
          </Dialog>
        }

        {
          activeModal === 'updateAsOrderedModal' &&
          <DealOrderedShipmentDialog toggleModal={toggleModal} orders={selectedOrders} getDealOrders={getDealOrders} />
        }

        {
          activeModal === "successDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Order Added</AlertDialogTitle>
                <AlertDialogDescription>
                  The deal order has been added successfully
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel onClick={resetForm}>Add Another</AlertDialogCancel>
                <AlertDialogAction onClick={()=>{
                  navigate(`${process.env.PUBLIC_URL}/orders/details/${orderID}`);
                }}>View Order</AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === "successOrderDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Order Updated</AlertDialogTitle>
                <AlertDialogDescription>
                  The deal order has been updated successfully
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel onClick={() => toggleModal(null)}>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === "cancelOrderDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>
                  {selectedOrders.length === 1 ? "Cancel Order" : "Cancel Selected Orders"}
                </AlertDialogTitle>
                <AlertDialogDescription>
                  {selectedOrders.length === 1
                    ? "Are you sure you want to cancel this order? This action cannot be undone."
                    : "Are you sure you want to cancel all selected orders? This action cannot be undone."}
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel onClick={() => toggleModal(null)}>Close</AlertDialogCancel>
                {
                  requesting ?
                  <AlertDialogAction disabled>Cancelling...</AlertDialogAction>
                  :
                  <AlertDialogAction className="bg-red-600 text-white hover:bg-red-700" onClick={confirmCancel}>Cancel Order(s)</AlertDialogAction>
                }
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === "successCancelledDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Cancelled Successfully</AlertDialogTitle>
                <AlertDialogDescription>
                  Order(s) cancelled successfully
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }
      </main>
    </React.Fragment>
  )
}
